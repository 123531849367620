/* You can add global styles to this file, and also import other style files */
@import "../node_modules/@angular/material/prebuilt-themes/deeppurple-amber.css";
@import './assets/scss/variables';
@import './assets/css/normalize.css';
@import './assets/css/webflow.css';
@import './assets/css/tinyview-panels-theme.webflow.css';

.cdk-virtual-scroll-orientation-horizontal .cdk-virtual-scroll-content-wrapper {
  min-height: 100%;
  display: flex !important;
}

.cdk-virtual-scroll .viewport {
  height: 84px;
  overflow-y: hidden;
  left: 20px;
  width: 96%;
  @media (max-width:991px) {
    width: 93%;
  }
}

.viewport .cdk-virtual-scroll-content-wrapper {
  display: flex;
  flex-direction: row;
}

.cdk-virtual-scroll .item {
  width: 130px;
  height: 100%;
  padding: 0 6px;
}

.viewport::-webkit-scrollbar{
  height: 6px;
  width: 12px;
}

.mat-form-field:not(.mat-form-field-appearance-legacy) .mat-form-field-prefix .mat-icon-button, .mat-form-field:not(.mat-form-field-appearance-legacy) .mat-form-field-suffix .mat-icon-button {
  outline: none !important;
}

// TODO: used prod cdn always
input[type="time"]::-webkit-calendar-picker-indicator {
  background: url("https://assets.tinyview.com/assets/images/clocks-removebg-preview.png") no-repeat !important;
  background-size: 15px !important;
  padding: 0px 5px 10px 5px !important;
  cursor: pointer;
  }
// .mat-datepicker-toggle input[type=tel] {
//   background: url("./assets/images/gift-img.png") no-repeat !important;
// }

.mat-icon-button .mat-button-base .cdk-focused .cdk-program-focused {
  outline: none !important;
}

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  // -webkit-appearance: none;
  margin: 0;
}

.mat-radio-button {
  margin-left: 10px !important;
  font-weight: 400 !important;
}

.mat-radio-button.mat-accent .mat-radio-inner-circle, .mat-radio-button.mat-accent .mat-radio-ripple .mat-ripple-element:not(.mat-radio-persistent-ripple), .mat-radio-button.mat-accent.mat-radio-checked .mat-radio-persistent-ripple, .mat-radio-button.mat-accent:active .mat-radio-persistent-ripple {
  background-color: #6d9eeb !important;
}

.mat-radio-button.mat-accent.mat-radio-checked .mat-radio-outer-circle {
  border-color: #6d9eeb !important;
}

label {
font-weight:normal !important;
}

.country-dropdown {
  width: 338px !important;
}

.loader {
  border: 8px solid #f3f3f3;
  border-radius: 50%;
  border-top: 8px solid $navbarBgColor;
  width: 40px;
  height: 40px;
  margin: 50px auto;
  margin-top: 130px;
  -webkit-animation: spin 2s linear infinite; /* Safari */
  animation: spin 2s linear infinite;
}
/* Firefox */
input[type=number] {
  -moz-appearance: textfield;
}
// .mat-form-field-wrapper {
//   padding-bottom: 0px !important;
// }
.mat-form-field-subscript-wrapper {
  position: relative !important;
}

// SCROLLBAR CSS STARTS - Do not alter without consulting UDIT
.metadata-section::-webkit-scrollbar {
  @media (max-width: 991px) {
    display: none;
  }
}

html::-webkit-scrollbar,
body::-webkit-scrollbar {
  @media (max-width: 991px) {
    display: none;
  }
}

/* Custom scrollbar style for webkit browsers (Safari, Chrome) */
::-webkit-scrollbar {
  @media (min-width: 991px) {
    width: 6px;
  }
}

/* Track */
::-webkit-scrollbar-track {
  @media (min-width: 991px) {
    background: transparent;
  }
}

/* Handle */
::-webkit-scrollbar-thumb {
  @media (min-width: 991px) {
    background: transparent;
    border-radius: 5px;
  }
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  @media (min-width: 991px) {
    background: transparent;
  }
}

.show-scrollbar::-webkit-scrollbar-thumb {
  @media (min-width: 991px) {
    background: #ccc;
  }
}

// [TODO]: Might be required (zendesk issue)

.show-scrollbar::-webkit-scrollbar-thumb:hover {
  @media (min-width: 991px) {
    background: var(--text);
  }
}

.hide-scrollbar::-webkit-scrollbar-thumb {
  @media (min-width: 991px) {
    background: transparent;
  }
}

.main-content::-webkit-scrollbar-track {
  @media (min-width: 991px) {
    background: white;
  }
}
// SCROLLBAR CSS ENDS

.cdk-global-overlay-wrapper, .cdk-overlay-container {
  z-index: 99999 !important;
}
.mat-select-value {
  color: gray !important;
}

input:-webkit-autofill {
  -webkit-box-shadow:0 0 0 50px white inset; /* Change the color to your own background color */
  -webkit-text-fill-color: gray;
}

input:-webkit-autofill:focus {
  -webkit-box-shadow: 0 0 0 50px white inset;/*your box-shadow*/
  -webkit-text-fill-color: gray;
}

.mat-datepicker-content .mat-calendar {
 z-index: 100000 !important;
}

.mat-form-field {
   display: block !important;
  /* position: relative; */
  text-align: left;
}

.loader {
  border: 8px solid #f3f3f3;
  border-radius: 50%;
  border-top: 8px solid $navbarBgColor;
  width: 40px;
  height: 40px;
  margin: 50px auto;
  margin-top: 130px;
  -webkit-animation: spin 2s linear infinite; /* Safari */
  animation: spin 2s linear infinite;
}

/* Safari */
@-webkit-keyframes spin {
  0% { -webkit-transform: rotate(0deg); }
  100% { -webkit-transform: rotate(360deg); }
}

@keyframes spin {
  0% { transform: rotate(0deg); }
  100% { transform: rotate(360deg); }
}

.app-body {
  // background-color: $whiteColor;
  // height:100vh; /* This is needed to overcome a Chrome bug. */
  // margin:0;     /* This is used to reset any browser-default margins */

  @media (max-width: 991px) {
    margin:0;
    height: auto;
  }
}

a:hover {
  text-decoration: none;
  color: inherit;
}

.tag-color {
  color: $blackColor !important;
}

.text-primary-1 {
  color: $blackColor;
}

.navbar-wrapper {
  background-color: white !important;

  .nav-menu-bg {
    background-color: $whiteColor !important;
    border: 1px solid $borderColor;
    box-shadow: 0 0 0 10000px rgba(0, 0, 0, .50); // darkens the back screen by 50%
    width: 270px;
    height: 100pc;

    @media screen and (max-width: 991px) {
      border-bottom-left-radius: 0;
      border-bottom-right-radius: 0;
    }
  }
}

 .modal-content {
  border-radius: 16px !important;
}

.section-container {
  // display: flex;
  // height: 100vh;
  // margin-left: 25%; /* Account for fixed nav bar */

  @media (max-width: 991px) {
    flex-direction: column;
    height: 100%;
    margin-left: 0;
    // margin-top: 70px;
  }
}

.side-nav {
  // background-color: $navbarBgColor;
  flex: 1;
  width: 30%;
  height: inherit;
  position: fixed;
  left: 0;
  top: 0;

  @media (max-width: 991px) {
    display: none;
    max-width: 100%;
  }
}

.detail-container {
  flex: 2;
  max-height: 100vh;
}


.user-badges {
  .badge-icon {
    position: relative;
    bottom: 2px;
    left: 3px;
    @media (max-width: 991px) {
      bottom: 6px;
    }
  }
}

.tv-red-color-png {
  filter: invert(40%) sepia(63%) saturate(4225%) hue-rotate(319deg) brightness(85%) contrast(110%);
}
@media (max-width: 991px) {
  .app-body {
     font-size: 16px !important;
  }
 }

.heading {
  .share-heading {
    text-align: center;
    border-bottom: none;
    margin-top: 10px;
  }
  p {
    padding: 10px 20px 10px;
    text-align: center;
    border-bottom: 1px solid rgb(222, 223, 224);
    font-weight: 400;
    &.gift-artist-heading {
      font-size: 14px;
      border-bottom: none;
    }
    &.share-sub-heading {
      border-bottom: none;
    }
  }
}

.show-modal {
  color: #101010;
  font-size: 16px;
  height: 190px;
  h3 {
    margin-top: 0px;
    font-family: Inter, sans-serif;
    color: #101010;
    line-height: 36px;
    font-weight: 500;
    letter-spacing: -0.01em;
    padding: 20px 20px 0px 20px;
    font-size: 18px;
    margin-bottom: 10px !important;
  }
  p {
    padding: 0px 20px !important;
    margin-bottom: 15px !important;
  }
  .secondary-btn {
    cursor: pointer;
    font-size: 14px;
    font-weight: 400;
    color: #666666;
    background-color: white;
    padding: 5px 20px;
    justify-content: center;
    align-items: center;
    border-radius: 0.25rem;
    border: 2px solid #efefef;
    outline: none;
    margin-left: 20px;
  }
}

.gift-items {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  margin: 0px 20px;
  // justify-content: space-evenly;
  line-height: 1.5;
  .gift-item {
    cursor: pointer;
    display: flex;
    flex-direction: column;
    flex-wrap: nowrap;
    color: $blackColor;
    align-items: center;
    font-size: 14px;
    margin: 10px 0px;
    width: 33.3333%;
    &.share-item {
      width: 25%;
    }
    .gift-img {
      width: 60px;
      height: 60px;
      @media (max-width: 991px) {
        width: 40px;
        height: 40px;
      }
    }
    .share-img {
      width: 60px;
      height: 60px;
      @media (max-width: 991px) {
        width: 40px;
        height: 40px;
      }
    }
    .gift-title {
      font-weight: 600;
      margin-top: 4px;
      text-align: center;
    }
    .share-title {
      font-size: 12px;
      margin-top: 4px;
    }
    &.disable-gift {
      opacity: 0.2;
      cursor: not-allowed;
    }
  }
}

.mobile-modal {
  margin: 0;
  position: fixed;
  bottom: 0;
  width: 100%;
  .modal-content {
    border-radius: 10px 10px 0px 0px !important;
  }
}

.cross-btn {
  filter: grayscale(100%) brightness(0%);
  position: absolute;
  cursor: pointer;
  width: 15px;
  right: 15px;
  top: 15px;
}

#toast-container>.toast-success {
  // Hard coding the toaster color, currently var(--toast-background) = #626262; and color: var(--toast-text) = #FFFFFF;
  // Reason: Toaster's background color is white for few second on successfull stripe payment, until API returns (--toast-background)
  // ALERT: Always keep  { background-color: var(--toast-background); color: var(--toast-text) }
  background-color: #626262 !important;
  color: #FFFFFF !important;
  opacity: 0.9 !important;
  background-image: none !important;
  position: relative;
  padding: 16px !important;
  top: 20px;
  right: 8px;
  @media (max-width: 991px) {
    right: 4px;
    top: 8px
  }
}
// White button with grey outline
.tv-btn-light {
  border-radius: 6px;
  cursor: pointer;
  white-space: nowrap;
  color: var(--text) !important;
  background-color: white;
  border: 1px solid #C4C4C4 !important;
  &:focus {
    box-shadow: none;
  }
  p {
    margin: 0;
  }
  &.btn {
    background-color: white !important;
  }
  &.disabled, &:disabled {
    cursor: not-allowed;
  }
  .p-bold {
    color: var(--text) !important;
  }
}

// Dark button with light outline
.tv-btn-dark {
  border-radius: 6px;
  cursor: pointer;
  white-space: nowrap;
  color: var(--text-inverse) !important;
  background-color: var(--text-bold);
  border: 1px solid var(--separators) !important;
  &:focus {
    box-shadow: none;
  }
  p {
    margin: 0;
  }
  &.btn {
    background-color: var(--text-bold) !important;
  }
  &.disabled, &:disabled {
    cursor: not-allowed;
  }
  .p-bold, .body-mini {
    color: var(--text-inverse) !important;
  }
}

// Red Button
.tv-btn-red {
  border-radius: 6px;
  cursor: pointer;
  white-space: nowrap;
  color: var(--text-inverse);
  &:focus {
    box-shadow: none;
  }
  p {
    margin: 0;
  }
  &.btn {
    background-color: var(--logo-red) !important;
  }
  &:hover {
    color: var(--text-inverse) !important;
  }
  &.disabled, &:disabled {
    cursor: not-allowed;
    background-color: var(--separators) !important;
    opacity: 1;
  }
  .p-bold {
    color: var(--text-inverse);
  }
}

.p-bottom, .h-bottom {
  margin-bottom: 10px;
}

.default-padding {
  padding: 0px 16px; // according to mockups
}

.icon-25 {
  height: 25px;
  width: 25px;
}

.icon-20 {
  height: 20px !important;
  width: 20px !important;
}

.icon-22 {
  height: 22px !important;
  width: 22px !important;
}

.icon-24 {
  height: 24px !important;
  width: 24px !important;
}

.side-border {
  border-right: 1px solid var(--chat-bubbles);
  border-left: 1px solid var(--chat-bubbles);
}

.pb-58 {
  padding-bottom: 58px;
}

.pb-100 {
  padding-bottom: 100px !important;
}

.pb-280 {
  padding-bottom: 280px !important;
}

.toast-text-png {
  filter: grayscale(100%) brightness(0%);
}

.open-in-app {
  background-color: var(--banner-background-error);
  display: flex;
  justify-content: space-between;
  position: relative;

  .toast-content {
    white-space: nowrap;
  }
  .toast-cross-icon {
    margin-left: 12px;
    @media (max-width: 410px) {
      margin-left: 8px;
    }
  }
  .toast-text {
    width: 121%;
    color: var(--banner-text-error);
    @media (max-width: 436px) {
      width: 103%;
    }
  }

  .banner-loader {
    position: relative;
    top: 3px;

    .spinner {
      width: 18px;
      height: 18px;
    }
  }

  .tv-icon {
    height: 28px;
    display: inline-block;
    padding: 0px;
    margin: 0px;
  }
  button {
    cursor: pointer;
    padding: 4px 8px;
    color: var(--text-inverse);
    background-color: var(--logo-red);
    border-radius: 6px;
    width: auto;
    &.disabled {
      cursor: not-allowed;
      background-color: var(--separators);
    }
  }
}

.tv-card {
  position: relative;
  background-color: white;
  padding: 20px;
  margin: 20px;
  border-radius: 10px;
  box-shadow: 0px 0px 32px 0px rgba(0, 0, 0, 0.06);
}

.icon-32 {
  width: 32px;
  height: 32px;
}

p, h2 {
  margin: 0;
}

.remove-bg-color {
  background-color: #fff !important;
}

.default-bg-color {
  background-color: var(--bg-color);
}

.p-20 {
  padding: 20px;
}

.mt-15 {
  margin-top: 15px;
}

.mx-20 {
  margin-right: 20px !important;
  margin-left: 20px !important;
}

.card-border {
  border: 1px solid #dedfe0;
}

.border-radius-10 {
  border-radius: 10px;
  border: 1px solid var(--borders);
}

// .quotes {
//   height: 20px;
//   width: 20px;
//   margin: 4px 0px 12px 0px;
// }

.comments {
  word-wrap: break-word !important;
}

a {
  color: var(--text);
}

.rm-box-shadow {
  box-shadow: none;
}

.m-20 {
  margin: 20px;
}

.ml-20 {
  margin-left: 20px;
}

.mb-60 {
  margin-bottom: 60px !important;
}

.mt-40 {
  margin-top: 40px;
}

.mt-20 {
  margin-top: 20px;
}

.my-20 {
  margin: 20px 0px;
}

.lh-2 {
  line-height: 2;
}

.cursor-pointer {
  cursor: pointer;
}

.custom-text-bold {
  color: var(--text-bold);
}

.tv-link {
  color: var(--logo-red);
  cursor: pointer;
  border-bottom: 1px solid;
  background-color: transparent;
  &:hover {
    color: var(--logo-red) !important;
  }
}

.error-text {
  color: var(--logo-red) !important;
  .copy-message { // please put this in a class, thanks
    position: relative;
    top: 1px;
    margin-left: 8px;
  }
}

.text-color {
  color: var(--text);
}

.icon-16 {
  height: 16px;
  width: 16px;
}

.full-height-mobile {
  @media (max-width: 991px) {
    height: calc(100vh - 58px); // subtracting 58px because of bottom nav
  }
}

.navigation-section {
  display: flex;
  border: 1px solid var(--borders);
  border-radius: 8px;
  width: 100%;
  // justify-content: space-between;
  cursor: pointer;
  &.read-comic {
    background-color: var(--chat-bubbles);
  }

  img {
    border-top-left-radius: 8px;
    border-bottom-left-radius: 8px;
    display: inline-block;
    max-width: 100%;
  }

  .episode-img {
    border-top-left-radius: 8px;
    border-bottom-left-radius: 8px;
    display: inline-block;
    max-width: 100%;
    width: 124px;
    height: 65px;
  }

  .episode-box {
    border-top-right-radius: 8px;
    border-bottom-right-radius: 8px;
    background-color: white;
    width: 64%;
    text-align: center;
    display: flex;

    .episode-text {
      color: #000000;
      font-size: 14px;
      font-weight: 400;
      margin: auto;

      // word-break: break-all;
      img {
        width: 20px;
      }
    }
  }
}

.action-container {
  color: $darkBlack;
  padding: 20px;
  font-size: 16px;
  font-weight: 400;
}

.btn-container {
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 10px 0px 20px;
  .btn {
    color: white;
    background-color: #f23545;
    font-weight: 400;
    justify-content: right;
    font-size: 14px;
    align-items: center;
    border-radius: 0.25rem;
  }
  .secondary-btn {
    cursor: pointer;
    font-size: 14px;
    font-weight: 400;
    color: #666666;
    background-color: white;
    padding: 5px 15px;
    justify-content: center;
    align-items: center;
    border-radius: 0.25rem;
    outline: none;
    /* height: 33px; */
    margin-top: 2px;
  }
  .btn-close {
    color: $lightGrayColor;
  }
  .loader-bar {
    border: 8px solid #f3f3f3;
    border-radius: 50%;
    border-top: 8px solid $redColor;
    width: 22px;
    height: 21px;
    -webkit-animation: spin 2s linear infinite;
    animation: spin 2s linear infinite;
    margin-left: 44px;
    margin-right: 36px;
  }
}

.text-inverse {
  color: var(--text-inverse) !important;
}

.image-border {
  border: 1px solid var(--chat-bubbles);
}

.title-head {
  max-width: 304px;
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
  @media (max-width:991px) {
    max-width: 280px;
  }
}

.undeline-link {
  color: var(--logoRed, #DF474B);
  font-size: 13px;
  font-style: normal;
  font-weight: 400;
  line-height: 140%;
  border-bottom: 1px solid;
  cursor: pointer;
}

.logout-modal-layout {
  margin-top: 300px !important;
}

.modal-sm-mobile {
  max-width: 305px;
  margin: auto;
}

.circle {
  background-color: var(--logo-red);
  border-radius: 50%;
  display: flex;
  width: 16px;
  height: 16px;
  justify-content: center;
  align-items: center;
  padding: 10px;
}

.oval {
  border-radius: 50%/100%;
  width: 26px;
  padding: 0;
}
.number {
  font-size: 10px;
  color: var(--text-inverse);
}

.shadow-on-hover {
  &:hover {
    color: var(--text);
    box-shadow: 4px 4px 8px 0px rgba(0, 0, 0, 0.12);
  }
}

.cursor-not-allowed {
  cursor: not-allowed !important;
}

.margin-bottom-2px {
  margin-bottom: 2px;
}

.mt-12 {
  margin-top: 12px;
}

.gift-icon {
  width: 24px;
  height: 24px;
  margin-bottom: 4px;
}

.highlight {
  border: 3px solid var(--highlight-borders);
}

/* HTML: <div class="loader-dots"></div> */
.loader-dots-white {
  width: 10px;
  aspect-ratio: 1;
  border-radius: 50%;
  animation: l5 1s infinite linear alternate;
}

@keyframes l5 {
  0%  {box-shadow: 20px 0 #fff, -20px 0 #ffffff20; background: #fff;}
  33% {box-shadow: 20px 0 #fff, -20px 0 #ffffff20; background: #ffffff20;}
  66% {box-shadow: 20px 0 #ffffff20, -20px 0 #fff; background: #ffffff20;}
  100% {box-shadow: 20px 0 #ffffff20, -20px 0 #fff; background: #fff;}
}