
.w-layout-grid {
  display: -ms-grid;
  display: grid;
  grid-auto-columns: 1fr;
  -ms-grid-columns: 1fr 1fr;
  grid-template-columns: 1fr 1fr;
  -ms-grid-rows: auto auto;
  grid-template-rows: auto auto;
  grid-row-gap: 16px;
  grid-column-gap: 16px;
}

.w-checkbox {
  display: block;
  margin-bottom: 5px;
  padding-left: 20px;
}

.w-checkbox::before {
  content: ' ';
  display: table;
  -ms-grid-column-span: 1;
  grid-column-end: 2;
  -ms-grid-column: 1;
  grid-column-start: 1;
  -ms-grid-row-span: 1;
  grid-row-end: 2;
  -ms-grid-row: 1;
  grid-row-start: 1;
}

.w-checkbox::after {
  content: ' ';
  display: table;
  -ms-grid-column-span: 1;
  grid-column-end: 2;
  -ms-grid-column: 1;
  grid-column-start: 1;
  -ms-grid-row-span: 1;
  grid-row-end: 2;
  -ms-grid-row: 1;
  grid-row-start: 1;
  clear: both;
}

.w-checkbox-input {
  float: left;
  margin-bottom: 0px;
  margin-left: -20px;
  margin-right: 0px;
  margin-top: 4px;
  line-height: normal;
}

.w-checkbox-input--inputType-custom {
  border-top-width: 1px;
  border-bottom-width: 1px;
  border-left-width: 1px;
  border-right-width: 1px;
  border-top-color: #ccc;
  border-bottom-color: #ccc;
  border-left-color: #ccc;
  border-right-color: #ccc;
  border-top-style: solid;
  border-bottom-style: solid;
  border-left-style: solid;
  border-right-style: solid;
  width: 12px;
  height: 12px;
  border-bottom-left-radius: 2px;
  border-bottom-right-radius: 2px;
  border-top-left-radius: 2px;
  border-top-right-radius: 2px;
}

.w-checkbox-input--inputType-custom.w--redirected-checked {
  background-color: #3898ec;
  border-top-color: #3898ec;
  border-bottom-color: #3898ec;
  border-left-color: #3898ec;
  border-right-color: #3898ec;
  background-image: url('https://d3e54v103j8qbb.cloudfront.net/static/custom-checkbox-checkmark.589d534424.svg');
  background-position: 50% 50%;
  background-size: cover;
  background-repeat: no-repeat;
}

.w-checkbox-input--inputType-custom.w--redirected-focus {
  box-shadow: 0px 0px 3px 1px #3898ec;
}

.w-form-formradioinput--inputType-custom {
  border-top-width: 1px;
  border-bottom-width: 1px;
  border-left-width: 1px;
  border-right-width: 1px;
  border-top-color: #ccc;
  border-bottom-color: #ccc;
  border-left-color: #ccc;
  border-right-color: #ccc;
  border-top-style: solid;
  border-bottom-style: solid;
  border-left-style: solid;
  border-right-style: solid;
  width: 12px;
  height: 12px;
  border-bottom-left-radius: 50%;
  border-bottom-right-radius: 50%;
  border-top-left-radius: 50%;
  border-top-right-radius: 50%;
}

.w-form-formradioinput--inputType-custom.w--redirected-focus {
  box-shadow: 0px 0px 3px 1px #3898ec;
}

.w-form-formradioinput--inputType-custom.w--redirected-checked {
  border-top-width: 4px;
  border-bottom-width: 4px;
  border-left-width: 4px;
  border-right-width: 4px;
  border-top-color: #3898ec;
  border-bottom-color: #3898ec;
  border-left-color: #3898ec;
  border-right-color: #3898ec;
}

.w-commerce-commerceaddtocartform {
  margin-top: 0px;
  margin-right: 0px;
  margin-bottom: 15px;
  margin-left: 0px;
}

.w-commerce-commerceaddtocartquantityinput {
  background-color: #fafafa;
  border-top-style: solid;
  border-top-width: 1px;
  border-top-color: #ddd;
  border-right-style: solid;
  border-right-width: 1px;
  border-right-color: #ddd;
  border-bottom-style: solid;
  border-bottom-width: 1px;
  border-bottom-color: #ddd;
  border-left-style: solid;
  border-left-width: 1px;
  border-left-color: #ddd;
  border-bottom-left-radius: 3px;
  border-bottom-right-radius: 3px;
  border-top-left-radius: 3px;
  border-top-right-radius: 3px;
  display: block;
  height: 38px;
  line-height: 20px;
  margin-bottom: 10px;
  padding-top: 8px;
  padding-right: 6px;
  padding-bottom: 8px;
  padding-left: 12px;
  width: 60px;
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
}

.w-commerce-commerceaddtocartquantityinput::-webkit-input-placeholder {
  color: #999;
}

.w-commerce-commerceaddtocartquantityinput:-ms-input-placeholder {
  color: #999;
}

.w-commerce-commerceaddtocartquantityinput::-ms-input-placeholder {
  color: #999;
}

.w-commerce-commerceaddtocartquantityinput::placeholder {
  color: #999;
}

.w-commerce-commerceaddtocartquantityinput:focus {
  border-top-color: #3898ec;
  border-right-color: #3898ec;
  border-bottom-color: #3898ec;
  border-left-color: #3898ec;
  outline-style: none;
}

.w-commerce-commerceaddtocartbutton {
  background-color: #3898ec;
  border-bottom-left-radius: 0px;
  border-bottom-right-radius: 0px;
  border-top-left-radius: 0px;
  border-top-right-radius: 0px;
  border-bottom-width: 0px;
  border-left-width: 0px;
  border-right-width: 0px;
  border-top-width: 0px;
  color: #fff;
  cursor: pointer;
  padding-top: 9px;
  padding-bottom: 9px;
  padding-left: 15px;
  padding-right: 15px;
  text-decoration: none;
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
}

.w-commerce-commerceaddtocartoutofstock {
  margin-top: 10px;
  padding-top: 10px;
  padding-bottom: 10px;
  padding-left: 10px;
  padding-right: 10px;
  background-color: #ddd;
}

.w-commerce-commerceaddtocarterror {
  margin-top: 10px;
  padding-top: 10px;
  padding-bottom: 10px;
  padding-left: 10px;
  padding-right: 10px;
  background-color: #ffdede;
}

.w-commerce-commercecheckoutformcontainer {
  width: 100%;
  min-height: 100vh;
  padding-top: 20px;
  padding-bottom: 20px;
  padding-right: 20px;
  padding-left: 20px;
  background-color: #f5f5f5;
}

.w-commerce-commercelayoutcontainer {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: start;
  -webkit-align-items: flex-start;
  -ms-flex-align: start;
  align-items: flex-start;
  -webkit-box-pack: center;
  -webkit-justify-content: center;
  -ms-flex-pack: center;
  justify-content: center;
}

.w-commerce-commercelayoutmain {
  -webkit-flex-basis: 800px;
  -ms-flex-preferred-size: 800px;
  flex-basis: 800px;
  -webkit-box-flex: 0;
  -webkit-flex-grow: 0;
  -ms-flex-positive: 0;
  flex-grow: 0;
  -webkit-flex-shrink: 1;
  -ms-flex-negative: 1;
  flex-shrink: 1;
  margin-right: 20px;
}

.w-commerce-commercecartapplepaybutton {
  background-color: #000;
  border-bottom-left-radius: 2px;
  border-bottom-right-radius: 2px;
  border-top-left-radius: 2px;
  border-top-right-radius: 2px;
  border-bottom-width: 0px;
  border-left-width: 0px;
  border-right-width: 0px;
  border-top-width: 0px;
  color: #fff;
  cursor: pointer;
  padding-top: 0px;
  padding-bottom: 0px;
  padding-left: 0px;
  padding-right: 0px;
  text-decoration: none;
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  margin-bottom: 8px;
  height: 38px;
  min-height: 30px;
}

.w-commerce-commercecartapplepayicon {
  width: 100%;
  height: 50%;
  min-height: 20px;
}

.w-commerce-commercecartquickcheckoutbutton {
  background-color: #000;
  border-bottom-left-radius: 2px;
  border-bottom-right-radius: 2px;
  border-top-left-radius: 2px;
  border-top-right-radius: 2px;
  border-bottom-width: 0px;
  border-left-width: 0px;
  border-right-width: 0px;
  border-top-width: 0px;
  color: #fff;
  cursor: pointer;
  padding-top: 0px;
  padding-bottom: 0px;
  padding-left: 15px;
  padding-right: 15px;
  text-decoration: none;
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  -webkit-box-pack: center;
  -webkit-justify-content: center;
  -ms-flex-pack: center;
  justify-content: center;
  margin-bottom: 8px;
  height: 38px;
}

.w-commerce-commercequickcheckoutgoogleicon {
  display: block;
  margin-right: 8px;
}

.w-commerce-commercequickcheckoutmicrosofticon {
  display: block;
  margin-right: 8px;
}

.w-commerce-commercecheckoutcustomerinfowrapper {
  margin-bottom: 20px;
}

.w-commerce-commercecheckoutblockheader {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: justify;
  -webkit-justify-content: space-between;
  -ms-flex-pack: justify;
  justify-content: space-between;
  -webkit-box-align: baseline;
  -webkit-align-items: baseline;
  -ms-flex-align: baseline;
  align-items: baseline;
  padding-top: 4px;
  padding-bottom: 4px;
  padding-right: 20px;
  padding-left: 20px;
  border-top-style: solid;
  border-top-width: 1px;
  border-top-color: #e6e6e6;
  border-bottom-style: solid;
  border-bottom-width: 1px;
  border-bottom-color: #e6e6e6;
  border-right-style: solid;
  border-right-width: 1px;
  border-right-color: #e6e6e6;
  border-left-style: solid;
  border-left-width: 1px;
  border-left-color: #e6e6e6;
  background-color: #fff;
}

.w-commerce-commercecheckoutblockcontent {
  padding-top: 20px;
  padding-bottom: 20px;
  padding-right: 20px;
  padding-left: 20px;
  border-bottom-style: solid;
  border-bottom-width: 1px;
  border-bottom-color: #e6e6e6;
  border-right-style: solid;
  border-right-width: 1px;
  border-right-color: #e6e6e6;
  border-left-style: solid;
  border-left-width: 1px;
  border-left-color: #e6e6e6;
  background-color: #fff;
}

.w-commerce-commercecheckoutlabel {
  margin-bottom: 8px;
}

.w-commerce-commercecheckoutemailinput {
  background-color: #fafafa;
  border-top-style: solid;
  border-top-width: 1px;
  border-top-color: #ddd;
  border-right-style: solid;
  border-right-width: 1px;
  border-right-color: #ddd;
  border-bottom-style: solid;
  border-bottom-width: 1px;
  border-bottom-color: #ddd;
  border-left-style: solid;
  border-left-width: 1px;
  border-left-color: #ddd;
  border-bottom-left-radius: 3px;
  border-bottom-right-radius: 3px;
  border-top-left-radius: 3px;
  border-top-right-radius: 3px;
  display: block;
  height: 38px;
  line-height: 20px;
  margin-bottom: 0px;
  padding-top: 8px;
  padding-right: 12px;
  padding-bottom: 8px;
  padding-left: 12px;
  width: 100%;
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
}

.w-commerce-commercecheckoutemailinput::-webkit-input-placeholder {
  color: #999;
}

.w-commerce-commercecheckoutemailinput:-ms-input-placeholder {
  color: #999;
}

.w-commerce-commercecheckoutemailinput::-ms-input-placeholder {
  color: #999;
}

.w-commerce-commercecheckoutemailinput::placeholder {
  color: #999;
}

.w-commerce-commercecheckoutemailinput:focus {
  border-top-color: #3898ec;
  border-right-color: #3898ec;
  border-bottom-color: #3898ec;
  border-left-color: #3898ec;
  outline-style: none;
}

.w-commerce-commercecheckoutshippingaddresswrapper {
  margin-bottom: 20px;
}

.w-commerce-commercecheckoutshippingfullname {
  background-color: #fafafa;
  border-top-style: solid;
  border-top-width: 1px;
  border-top-color: #ddd;
  border-right-style: solid;
  border-right-width: 1px;
  border-right-color: #ddd;
  border-bottom-style: solid;
  border-bottom-width: 1px;
  border-bottom-color: #ddd;
  border-left-style: solid;
  border-left-width: 1px;
  border-left-color: #ddd;
  border-bottom-left-radius: 3px;
  border-bottom-right-radius: 3px;
  border-top-left-radius: 3px;
  border-top-right-radius: 3px;
  display: block;
  height: 38px;
  line-height: 20px;
  margin-bottom: 16px;
  padding-top: 8px;
  padding-right: 12px;
  padding-bottom: 8px;
  padding-left: 12px;
  width: 100%;
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
}

.w-commerce-commercecheckoutshippingfullname::-webkit-input-placeholder {
  color: #999;
}

.w-commerce-commercecheckoutshippingfullname:-ms-input-placeholder {
  color: #999;
}

.w-commerce-commercecheckoutshippingfullname::-ms-input-placeholder {
  color: #999;
}

.w-commerce-commercecheckoutshippingfullname::placeholder {
  color: #999;
}

.w-commerce-commercecheckoutshippingfullname:focus {
  border-top-color: #3898ec;
  border-right-color: #3898ec;
  border-bottom-color: #3898ec;
  border-left-color: #3898ec;
  outline-style: none;
}

.w-commerce-commercecheckoutshippingstreetaddress {
  background-color: #fafafa;
  border-top-style: solid;
  border-top-width: 1px;
  border-top-color: #ddd;
  border-right-style: solid;
  border-right-width: 1px;
  border-right-color: #ddd;
  border-bottom-style: solid;
  border-bottom-width: 1px;
  border-bottom-color: #ddd;
  border-left-style: solid;
  border-left-width: 1px;
  border-left-color: #ddd;
  border-bottom-left-radius: 3px;
  border-bottom-right-radius: 3px;
  border-top-left-radius: 3px;
  border-top-right-radius: 3px;
  display: block;
  height: 38px;
  line-height: 20px;
  margin-bottom: 16px;
  padding-top: 8px;
  padding-right: 12px;
  padding-bottom: 8px;
  padding-left: 12px;
  width: 100%;
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
}

.w-commerce-commercecheckoutshippingstreetaddress::-webkit-input-placeholder {
  color: #999;
}

.w-commerce-commercecheckoutshippingstreetaddress:-ms-input-placeholder {
  color: #999;
}

.w-commerce-commercecheckoutshippingstreetaddress::-ms-input-placeholder {
  color: #999;
}

.w-commerce-commercecheckoutshippingstreetaddress::placeholder {
  color: #999;
}

.w-commerce-commercecheckoutshippingstreetaddress:focus {
  border-top-color: #3898ec;
  border-right-color: #3898ec;
  border-bottom-color: #3898ec;
  border-left-color: #3898ec;
  outline-style: none;
}

.w-commerce-commercecheckoutshippingstreetaddressoptional {
  background-color: #fafafa;
  border-top-style: solid;
  border-top-width: 1px;
  border-top-color: #ddd;
  border-right-style: solid;
  border-right-width: 1px;
  border-right-color: #ddd;
  border-bottom-style: solid;
  border-bottom-width: 1px;
  border-bottom-color: #ddd;
  border-left-style: solid;
  border-left-width: 1px;
  border-left-color: #ddd;
  border-bottom-left-radius: 3px;
  border-bottom-right-radius: 3px;
  border-top-left-radius: 3px;
  border-top-right-radius: 3px;
  display: block;
  height: 38px;
  line-height: 20px;
  margin-bottom: 16px;
  padding-top: 8px;
  padding-right: 12px;
  padding-bottom: 8px;
  padding-left: 12px;
  width: 100%;
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
}

.w-commerce-commercecheckoutshippingstreetaddressoptional::-webkit-input-placeholder {
  color: #999;
}

.w-commerce-commercecheckoutshippingstreetaddressoptional:-ms-input-placeholder {
  color: #999;
}

.w-commerce-commercecheckoutshippingstreetaddressoptional::-ms-input-placeholder {
  color: #999;
}

.w-commerce-commercecheckoutshippingstreetaddressoptional::placeholder {
  color: #999;
}

.w-commerce-commercecheckoutshippingstreetaddressoptional:focus {
  border-top-color: #3898ec;
  border-right-color: #3898ec;
  border-bottom-color: #3898ec;
  border-left-color: #3898ec;
  outline-style: none;
}

.w-commerce-commercecheckoutrow {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  margin-right: -8px;
  margin-left: -8px;
}

.w-commerce-commercecheckoutcolumn {
  padding-right: 8px;
  padding-left: 8px;
  -webkit-box-flex: 1;
  -webkit-flex-grow: 1;
  -ms-flex-positive: 1;
  flex-grow: 1;
  -webkit-flex-shrink: 1;
  -ms-flex-negative: 1;
  flex-shrink: 1;
  -webkit-flex-basis: 0%;
  -ms-flex-preferred-size: 0%;
  flex-basis: 0%;
}

.w-commerce-commercecheckoutshippingcity {
  background-color: #fafafa;
  border-top-style: solid;
  border-top-width: 1px;
  border-top-color: #ddd;
  border-right-style: solid;
  border-right-width: 1px;
  border-right-color: #ddd;
  border-bottom-style: solid;
  border-bottom-width: 1px;
  border-bottom-color: #ddd;
  border-left-style: solid;
  border-left-width: 1px;
  border-left-color: #ddd;
  border-bottom-left-radius: 3px;
  border-bottom-right-radius: 3px;
  border-top-left-radius: 3px;
  border-top-right-radius: 3px;
  display: block;
  height: 38px;
  line-height: 20px;
  margin-bottom: 16px;
  padding-top: 8px;
  padding-right: 12px;
  padding-bottom: 8px;
  padding-left: 12px;
  width: 100%;
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
}

.w-commerce-commercecheckoutshippingcity::-webkit-input-placeholder {
  color: #999;
}

.w-commerce-commercecheckoutshippingcity:-ms-input-placeholder {
  color: #999;
}

.w-commerce-commercecheckoutshippingcity::-ms-input-placeholder {
  color: #999;
}

.w-commerce-commercecheckoutshippingcity::placeholder {
  color: #999;
}

.w-commerce-commercecheckoutshippingcity:focus {
  border-top-color: #3898ec;
  border-right-color: #3898ec;
  border-bottom-color: #3898ec;
  border-left-color: #3898ec;
  outline-style: none;
}

.w-commerce-commercecheckoutshippingstateprovince {
  background-color: #fafafa;
  border-top-style: solid;
  border-top-width: 1px;
  border-top-color: #ddd;
  border-right-style: solid;
  border-right-width: 1px;
  border-right-color: #ddd;
  border-bottom-style: solid;
  border-bottom-width: 1px;
  border-bottom-color: #ddd;
  border-left-style: solid;
  border-left-width: 1px;
  border-left-color: #ddd;
  border-bottom-left-radius: 3px;
  border-bottom-right-radius: 3px;
  border-top-left-radius: 3px;
  border-top-right-radius: 3px;
  display: block;
  height: 38px;
  line-height: 20px;
  margin-bottom: 16px;
  padding-top: 8px;
  padding-right: 12px;
  padding-bottom: 8px;
  padding-left: 12px;
  width: 100%;
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
}

.w-commerce-commercecheckoutshippingstateprovince::-webkit-input-placeholder {
  color: #999;
}

.w-commerce-commercecheckoutshippingstateprovince:-ms-input-placeholder {
  color: #999;
}

.w-commerce-commercecheckoutshippingstateprovince::-ms-input-placeholder {
  color: #999;
}

.w-commerce-commercecheckoutshippingstateprovince::placeholder {
  color: #999;
}

.w-commerce-commercecheckoutshippingstateprovince:focus {
  border-top-color: #3898ec;
  border-right-color: #3898ec;
  border-bottom-color: #3898ec;
  border-left-color: #3898ec;
  outline-style: none;
}

.w-commerce-commercecheckoutshippingzippostalcode {
  background-color: #fafafa;
  border-top-style: solid;
  border-top-width: 1px;
  border-top-color: #ddd;
  border-right-style: solid;
  border-right-width: 1px;
  border-right-color: #ddd;
  border-bottom-style: solid;
  border-bottom-width: 1px;
  border-bottom-color: #ddd;
  border-left-style: solid;
  border-left-width: 1px;
  border-left-color: #ddd;
  border-bottom-left-radius: 3px;
  border-bottom-right-radius: 3px;
  border-top-left-radius: 3px;
  border-top-right-radius: 3px;
  display: block;
  height: 38px;
  line-height: 20px;
  margin-bottom: 16px;
  padding-top: 8px;
  padding-right: 12px;
  padding-bottom: 8px;
  padding-left: 12px;
  width: 100%;
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
}

.w-commerce-commercecheckoutshippingzippostalcode::-webkit-input-placeholder {
  color: #999;
}

.w-commerce-commercecheckoutshippingzippostalcode:-ms-input-placeholder {
  color: #999;
}

.w-commerce-commercecheckoutshippingzippostalcode::-ms-input-placeholder {
  color: #999;
}

.w-commerce-commercecheckoutshippingzippostalcode::placeholder {
  color: #999;
}

.w-commerce-commercecheckoutshippingzippostalcode:focus {
  border-top-color: #3898ec;
  border-right-color: #3898ec;
  border-bottom-color: #3898ec;
  border-left-color: #3898ec;
  outline-style: none;
}

.w-commerce-commercecheckoutshippingcountryselector {
  background-color: #fafafa;
  border-top-style: solid;
  border-top-width: 1px;
  border-top-color: #ddd;
  border-right-style: solid;
  border-right-width: 1px;
  border-right-color: #ddd;
  border-bottom-style: solid;
  border-bottom-width: 1px;
  border-bottom-color: #ddd;
  border-left-style: solid;
  border-left-width: 1px;
  border-left-color: #ddd;
  border-bottom-left-radius: 3px;
  border-bottom-right-radius: 3px;
  border-top-left-radius: 3px;
  border-top-right-radius: 3px;
  display: block;
  height: 38px;
  line-height: 20px;
  margin-bottom: 0px;
  padding-top: 8px;
  padding-right: 12px;
  padding-bottom: 8px;
  padding-left: 12px;
  width: 100%;
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
}

.w-commerce-commercecheckoutshippingcountryselector::-webkit-input-placeholder {
  color: #999;
}

.w-commerce-commercecheckoutshippingcountryselector:-ms-input-placeholder {
  color: #999;
}

.w-commerce-commercecheckoutshippingcountryselector::-ms-input-placeholder {
  color: #999;
}

.w-commerce-commercecheckoutshippingcountryselector::placeholder {
  color: #999;
}

.w-commerce-commercecheckoutshippingcountryselector:focus {
  border-top-color: #3898ec;
  border-right-color: #3898ec;
  border-bottom-color: #3898ec;
  border-left-color: #3898ec;
  outline-style: none;
}

.w-commerce-commercecheckoutshippingmethodswrapper {
  margin-bottom: 20px;
}

.w-commerce-commercecheckoutshippingmethodslist {
  border-left-color: #e6e6e6;
  border-left-width: 1px;
  border-left-style: solid;
  border-right-color: #e6e6e6;
  border-right-width: 1px;
  border-right-style: solid;
}

/* .w-commerce-commercecheckoutshippingmethoditem {
  padding-top: 16px;
  padding-bottom: 16px;
  padding-right: 16px;
  padding-left: 16px;
  font-weight: $extraLightBold;
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
  -webkit-flex-direction: row;
  -ms-flex-direction: row;
  flex-direction: row;
  border-bottom-color: #e6e6e6;
  border-bottom-width: 1px;
  border-bottom-style: solid;
  margin-bottom: 0px;
  background-color: #fff;
  -webkit-box-align: baseline;
  -webkit-align-items: baseline;
  -ms-flex-align: baseline;
  align-items: baseline;
} */

.w-commerce-commercecheckoutshippingmethoddescriptionblock {
  margin-left: 12px;
  margin-right: 12px;
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -webkit-flex-direction: column;
  -ms-flex-direction: column;
  flex-direction: column;
  -webkit-box-flex: 1;
  -webkit-flex-grow: 1;
  -ms-flex-positive: 1;
  flex-grow: 1;
}

.w-commerce-commerceboldtextblock {
  font-weight: 700;
}

.w-commerce-commercecheckoutshippingmethodsemptystate {
  text-align: center;
  padding-left: 16px;
  padding-right: 16px;
  padding-top: 64px;
  padding-bottom: 64px;
  border-left-style: solid;
  border-left-width: 1px;
  border-left-color: #e6e6e6;
  border-right-style: solid;
  border-right-width: 1px;
  border-right-color: #e6e6e6;
  border-bottom-style: solid;
  border-bottom-width: 1px;
  border-bottom-color: #e6e6e6;
  background-color: #fff;
}

.w-commerce-commercecheckoutpaymentinfowrapper {
  margin-bottom: 20px;
}

.w-commerce-commercecheckoutcardnumber {
  background-color: #fafafa;
  border-top-style: solid;
  border-top-width: 1px;
  border-top-color: #ddd;
  border-right-style: solid;
  border-right-width: 1px;
  border-right-color: #ddd;
  border-bottom-style: solid;
  border-bottom-width: 1px;
  border-bottom-color: #ddd;
  border-left-style: solid;
  border-left-width: 1px;
  border-left-color: #ddd;
  border-bottom-left-radius: 3px;
  border-bottom-right-radius: 3px;
  border-top-left-radius: 3px;
  border-top-right-radius: 3px;
  display: block;
  height: 38px;
  line-height: 20px;
  margin-bottom: 16px;
  padding-top: 8px;
  padding-right: 12px;
  padding-bottom: 8px;
  padding-left: 12px;
  width: 100%;
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  cursor: text;
}

.w-commerce-commercecheckoutcardnumber::-webkit-input-placeholder {
  color: #999;
}

.w-commerce-commercecheckoutcardnumber:-ms-input-placeholder {
  color: #999;
}

.w-commerce-commercecheckoutcardnumber::-ms-input-placeholder {
  color: #999;
}

.w-commerce-commercecheckoutcardnumber::placeholder {
  color: #999;
}

.w-commerce-commercecheckoutcardnumber:focus {
  border-top-color: #3898ec;
  border-right-color: #3898ec;
  border-bottom-color: #3898ec;
  border-left-color: #3898ec;
  outline-style: none;
}

.w-commerce-commercecheckoutcardnumber.-wfp-focus {
  border-top-color: #3898ec;
  border-right-color: #3898ec;
  border-bottom-color: #3898ec;
  border-left-color: #3898ec;
  outline-style: none;
}

.w-commerce-commercecheckoutcardexpirationdate {
  background-color: #fafafa;
  border-top-style: solid;
  border-top-width: 1px;
  border-top-color: #ddd;
  border-right-style: solid;
  border-right-width: 1px;
  border-right-color: #ddd;
  border-bottom-style: solid;
  border-bottom-width: 1px;
  border-bottom-color: #ddd;
  border-left-style: solid;
  border-left-width: 1px;
  border-left-color: #ddd;
  border-bottom-left-radius: 3px;
  border-bottom-right-radius: 3px;
  border-top-left-radius: 3px;
  border-top-right-radius: 3px;
  display: block;
  height: 38px;
  line-height: 20px;
  margin-bottom: 16px;
  padding-top: 8px;
  padding-right: 12px;
  padding-bottom: 8px;
  padding-left: 12px;
  width: 100%;
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  cursor: text;
}

.w-commerce-commercecheckoutcardexpirationdate::-webkit-input-placeholder {
  color: #999;
}

.w-commerce-commercecheckoutcardexpirationdate:-ms-input-placeholder {
  color: #999;
}

.w-commerce-commercecheckoutcardexpirationdate::-ms-input-placeholder {
  color: #999;
}

.w-commerce-commercecheckoutcardexpirationdate::placeholder {
  color: #999;
}

.w-commerce-commercecheckoutcardexpirationdate:focus {
  border-top-color: #3898ec;
  border-right-color: #3898ec;
  border-bottom-color: #3898ec;
  border-left-color: #3898ec;
  outline-style: none;
}

.w-commerce-commercecheckoutcardexpirationdate.-wfp-focus {
  border-top-color: #3898ec;
  border-right-color: #3898ec;
  border-bottom-color: #3898ec;
  border-left-color: #3898ec;
  outline-style: none;
}

.w-commerce-commercecheckoutcardsecuritycode {
  background-color: #fafafa;
  border-top-style: solid;
  border-top-width: 1px;
  border-top-color: #ddd;
  border-right-style: solid;
  border-right-width: 1px;
  border-right-color: #ddd;
  border-bottom-style: solid;
  border-bottom-width: 1px;
  border-bottom-color: #ddd;
  border-left-style: solid;
  border-left-width: 1px;
  border-left-color: #ddd;
  border-bottom-left-radius: 3px;
  border-bottom-right-radius: 3px;
  border-top-left-radius: 3px;
  border-top-right-radius: 3px;
  display: block;
  height: 38px;
  line-height: 20px;
  margin-bottom: 16px;
  padding-top: 8px;
  padding-right: 12px;
  padding-bottom: 8px;
  padding-left: 12px;
  width: 100%;
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  cursor: text;
}

.w-commerce-commercecheckoutcardsecuritycode::-webkit-input-placeholder {
  color: #999;
}

.w-commerce-commercecheckoutcardsecuritycode:-ms-input-placeholder {
  color: #999;
}

.w-commerce-commercecheckoutcardsecuritycode::-ms-input-placeholder {
  color: #999;
}

.w-commerce-commercecheckoutcardsecuritycode::placeholder {
  color: #999;
}

.w-commerce-commercecheckoutcardsecuritycode:focus {
  border-top-color: #3898ec;
  border-right-color: #3898ec;
  border-bottom-color: #3898ec;
  border-left-color: #3898ec;
  outline-style: none;
}

.w-commerce-commercecheckoutcardsecuritycode.-wfp-focus {
  border-top-color: #3898ec;
  border-right-color: #3898ec;
  border-bottom-color: #3898ec;
  border-left-color: #3898ec;
  outline-style: none;
}

.w-commerce-commercecheckoutbillingaddresstogglewrapper {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
  -webkit-flex-direction: row;
  -ms-flex-direction: row;
  flex-direction: row;
}

.w-commerce-commercecheckoutbillingaddresstogglecheckbox {
  margin-top: 4px;
}

/* .w-commerce-commercecheckoutbillingaddresstogglelabel {
  font-weight: $extraLightBold;
  margin-left: 8px;
} */

.w-commerce-commercecheckoutbillingaddresswrapper {
  margin-top: 16px;
  margin-bottom: 20px;
}

.w-commerce-commercecheckoutbillingfullname {
  background-color: #fafafa;
  border-top-style: solid;
  border-top-width: 1px;
  border-top-color: #ddd;
  border-right-style: solid;
  border-right-width: 1px;
  border-right-color: #ddd;
  border-bottom-style: solid;
  border-bottom-width: 1px;
  border-bottom-color: #ddd;
  border-left-style: solid;
  border-left-width: 1px;
  border-left-color: #ddd;
  border-bottom-left-radius: 3px;
  border-bottom-right-radius: 3px;
  border-top-left-radius: 3px;
  border-top-right-radius: 3px;
  display: block;
  height: 38px;
  line-height: 20px;
  margin-bottom: 16px;
  padding-top: 8px;
  padding-right: 12px;
  padding-bottom: 8px;
  padding-left: 12px;
  width: 100%;
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
}

.w-commerce-commercecheckoutbillingfullname::-webkit-input-placeholder {
  color: #999;
}

.w-commerce-commercecheckoutbillingfullname:-ms-input-placeholder {
  color: #999;
}

.w-commerce-commercecheckoutbillingfullname::-ms-input-placeholder {
  color: #999;
}

.w-commerce-commercecheckoutbillingfullname::placeholder {
  color: #999;
}

.w-commerce-commercecheckoutbillingfullname:focus {
  border-top-color: #3898ec;
  border-right-color: #3898ec;
  border-bottom-color: #3898ec;
  border-left-color: #3898ec;
  outline-style: none;
}

.w-commerce-commercecheckoutbillingstreetaddress {
  background-color: #fafafa;
  border-top-style: solid;
  border-top-width: 1px;
  border-top-color: #ddd;
  border-right-style: solid;
  border-right-width: 1px;
  border-right-color: #ddd;
  border-bottom-style: solid;
  border-bottom-width: 1px;
  border-bottom-color: #ddd;
  border-left-style: solid;
  border-left-width: 1px;
  border-left-color: #ddd;
  border-bottom-left-radius: 3px;
  border-bottom-right-radius: 3px;
  border-top-left-radius: 3px;
  border-top-right-radius: 3px;
  display: block;
  height: 38px;
  line-height: 20px;
  margin-bottom: 16px;
  padding-top: 8px;
  padding-right: 12px;
  padding-bottom: 8px;
  padding-left: 12px;
  width: 100%;
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
}

.w-commerce-commercecheckoutbillingstreetaddress::-webkit-input-placeholder {
  color: #999;
}

.w-commerce-commercecheckoutbillingstreetaddress:-ms-input-placeholder {
  color: #999;
}

.w-commerce-commercecheckoutbillingstreetaddress::-ms-input-placeholder {
  color: #999;
}

.w-commerce-commercecheckoutbillingstreetaddress::placeholder {
  color: #999;
}

.w-commerce-commercecheckoutbillingstreetaddress:focus {
  border-top-color: #3898ec;
  border-right-color: #3898ec;
  border-bottom-color: #3898ec;
  border-left-color: #3898ec;
  outline-style: none;
}

.w-commerce-commercecheckoutbillingstreetaddressoptional {
  background-color: #fafafa;
  border-top-style: solid;
  border-top-width: 1px;
  border-top-color: #ddd;
  border-right-style: solid;
  border-right-width: 1px;
  border-right-color: #ddd;
  border-bottom-style: solid;
  border-bottom-width: 1px;
  border-bottom-color: #ddd;
  border-left-style: solid;
  border-left-width: 1px;
  border-left-color: #ddd;
  border-bottom-left-radius: 3px;
  border-bottom-right-radius: 3px;
  border-top-left-radius: 3px;
  border-top-right-radius: 3px;
  display: block;
  height: 38px;
  line-height: 20px;
  margin-bottom: 16px;
  padding-top: 8px;
  padding-right: 12px;
  padding-bottom: 8px;
  padding-left: 12px;
  width: 100%;
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
}

.w-commerce-commercecheckoutbillingstreetaddressoptional::-webkit-input-placeholder {
  color: #999;
}

.w-commerce-commercecheckoutbillingstreetaddressoptional:-ms-input-placeholder {
  color: #999;
}

.w-commerce-commercecheckoutbillingstreetaddressoptional::-ms-input-placeholder {
  color: #999;
}

.w-commerce-commercecheckoutbillingstreetaddressoptional::placeholder {
  color: #999;
}

.w-commerce-commercecheckoutbillingstreetaddressoptional:focus {
  border-top-color: #3898ec;
  border-right-color: #3898ec;
  border-bottom-color: #3898ec;
  border-left-color: #3898ec;
  outline-style: none;
}

.w-commerce-commercecheckoutbillingcity {
  background-color: #fafafa;
  border-top-style: solid;
  border-top-width: 1px;
  border-top-color: #ddd;
  border-right-style: solid;
  border-right-width: 1px;
  border-right-color: #ddd;
  border-bottom-style: solid;
  border-bottom-width: 1px;
  border-bottom-color: #ddd;
  border-left-style: solid;
  border-left-width: 1px;
  border-left-color: #ddd;
  border-bottom-left-radius: 3px;
  border-bottom-right-radius: 3px;
  border-top-left-radius: 3px;
  border-top-right-radius: 3px;
  display: block;
  height: 38px;
  line-height: 20px;
  margin-bottom: 16px;
  padding-top: 8px;
  padding-right: 12px;
  padding-bottom: 8px;
  padding-left: 12px;
  width: 100%;
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
}

.w-commerce-commercecheckoutbillingcity::-webkit-input-placeholder {
  color: #999;
}

.w-commerce-commercecheckoutbillingcity:-ms-input-placeholder {
  color: #999;
}

.w-commerce-commercecheckoutbillingcity::-ms-input-placeholder {
  color: #999;
}

.w-commerce-commercecheckoutbillingcity::placeholder {
  color: #999;
}

.w-commerce-commercecheckoutbillingcity:focus {
  border-top-color: #3898ec;
  border-right-color: #3898ec;
  border-bottom-color: #3898ec;
  border-left-color: #3898ec;
  outline-style: none;
}

.w-commerce-commercecheckoutbillingstateprovince {
  background-color: #fafafa;
  border-top-style: solid;
  border-top-width: 1px;
  border-top-color: #ddd;
  border-right-style: solid;
  border-right-width: 1px;
  border-right-color: #ddd;
  border-bottom-style: solid;
  border-bottom-width: 1px;
  border-bottom-color: #ddd;
  border-left-style: solid;
  border-left-width: 1px;
  border-left-color: #ddd;
  border-bottom-left-radius: 3px;
  border-bottom-right-radius: 3px;
  border-top-left-radius: 3px;
  border-top-right-radius: 3px;
  display: block;
  height: 38px;
  line-height: 20px;
  margin-bottom: 16px;
  padding-top: 8px;
  padding-right: 12px;
  padding-bottom: 8px;
  padding-left: 12px;
  width: 100%;
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
}

.w-commerce-commercecheckoutbillingstateprovince::-webkit-input-placeholder {
  color: #999;
}

.w-commerce-commercecheckoutbillingstateprovince:-ms-input-placeholder {
  color: #999;
}

.w-commerce-commercecheckoutbillingstateprovince::-ms-input-placeholder {
  color: #999;
}

.w-commerce-commercecheckoutbillingstateprovince::placeholder {
  color: #999;
}

.w-commerce-commercecheckoutbillingstateprovince:focus {
  border-top-color: #3898ec;
  border-right-color: #3898ec;
  border-bottom-color: #3898ec;
  border-left-color: #3898ec;
  outline-style: none;
}

.w-commerce-commercecheckoutbillingzippostalcode {
  background-color: #fafafa;
  border-top-style: solid;
  border-top-width: 1px;
  border-top-color: #ddd;
  border-right-style: solid;
  border-right-width: 1px;
  border-right-color: #ddd;
  border-bottom-style: solid;
  border-bottom-width: 1px;
  border-bottom-color: #ddd;
  border-left-style: solid;
  border-left-width: 1px;
  border-left-color: #ddd;
  border-bottom-left-radius: 3px;
  border-bottom-right-radius: 3px;
  border-top-left-radius: 3px;
  border-top-right-radius: 3px;
  display: block;
  height: 38px;
  line-height: 20px;
  margin-bottom: 16px;
  padding-top: 8px;
  padding-right: 12px;
  padding-bottom: 8px;
  padding-left: 12px;
  width: 100%;
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
}

.w-commerce-commercecheckoutbillingzippostalcode::-webkit-input-placeholder {
  color: #999;
}

.w-commerce-commercecheckoutbillingzippostalcode:-ms-input-placeholder {
  color: #999;
}

.w-commerce-commercecheckoutbillingzippostalcode::-ms-input-placeholder {
  color: #999;
}

.w-commerce-commercecheckoutbillingzippostalcode::placeholder {
  color: #999;
}

.w-commerce-commercecheckoutbillingzippostalcode:focus {
  border-top-color: #3898ec;
  border-right-color: #3898ec;
  border-bottom-color: #3898ec;
  border-left-color: #3898ec;
  outline-style: none;
}

.w-commerce-commercecheckoutbillingcountryselector {
  background-color: #fafafa;
  border-top-style: solid;
  border-top-width: 1px;
  border-top-color: #ddd;
  border-right-style: solid;
  border-right-width: 1px;
  border-right-color: #ddd;
  border-bottom-style: solid;
  border-bottom-width: 1px;
  border-bottom-color: #ddd;
  border-left-style: solid;
  border-left-width: 1px;
  border-left-color: #ddd;
  border-bottom-left-radius: 3px;
  border-bottom-right-radius: 3px;
  border-top-left-radius: 3px;
  border-top-right-radius: 3px;
  display: block;
  height: 38px;
  line-height: 20px;
  margin-bottom: 0px;
  padding-top: 8px;
  padding-right: 12px;
  padding-bottom: 8px;
  padding-left: 12px;
  width: 100%;
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
}

.w-commerce-commercecheckoutbillingcountryselector::-webkit-input-placeholder {
  color: #999;
}

.w-commerce-commercecheckoutbillingcountryselector:-ms-input-placeholder {
  color: #999;
}

.w-commerce-commercecheckoutbillingcountryselector::-ms-input-placeholder {
  color: #999;
}

.w-commerce-commercecheckoutbillingcountryselector::placeholder {
  color: #999;
}

.w-commerce-commercecheckoutbillingcountryselector:focus {
  border-top-color: #3898ec;
  border-right-color: #3898ec;
  border-bottom-color: #3898ec;
  border-left-color: #3898ec;
  outline-style: none;
}

.w-commerce-commercecheckoutorderitemswrapper {
  margin-bottom: 20px;
}

.w-commerce-commercecheckoutsummaryblockheader {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: justify;
  -webkit-justify-content: space-between;
  -ms-flex-pack: justify;
  justify-content: space-between;
  -webkit-box-align: baseline;
  -webkit-align-items: baseline;
  -ms-flex-align: baseline;
  align-items: baseline;
  padding-top: 4px;
  padding-bottom: 4px;
  padding-right: 20px;
  padding-left: 20px;
  border-top-style: solid;
  border-top-width: 1px;
  border-top-color: #e6e6e6;
  border-bottom-style: solid;
  border-bottom-width: 1px;
  border-bottom-color: #e6e6e6;
  border-right-style: solid;
  border-right-width: 1px;
  border-right-color: #e6e6e6;
  border-left-style: solid;
  border-left-width: 1px;
  border-left-color: #e6e6e6;
  background-color: #fff;
}

.w-commerce-commercecheckoutorderitemslist {
  margin-bottom: -20px;
}

.w-commerce-commercecheckoutorderitem {
  margin-bottom: 20px;
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
}

.w-commerce-commercecartitemimage {
  width: 60px;
  height: 0%;
}

.w-commerce-commercecheckoutorderitemdescriptionwrapper {
  margin-left: 16px;
  margin-right: 16px;
  -webkit-box-flex: 1;
  -webkit-flex-grow: 1;
  -ms-flex-positive: 1;
  flex-grow: 1;
}

.w-commerce-commercecheckoutorderitemquantitywrapper {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  white-space: pre-wrap;
}

.w-commerce-commercecheckoutorderitemoptionlist {
  text-decoration: none;
  padding-left: 0px;
  margin-bottom: 0px;
  list-style-type: none;
}

.w-commerce-commercelayoutsidebar {
  -webkit-flex-basis: 320px;
  -ms-flex-preferred-size: 320px;
  flex-basis: 320px;
  -webkit-box-flex: 0;
  -webkit-flex-grow: 0;
  -ms-flex-positive: 0;
  flex-grow: 0;
  -webkit-flex-shrink: 0;
  -ms-flex-negative: 0;
  flex-shrink: 0;
  position: -webkit-sticky;
  position: sticky;
  top: 20px;
}

.w-commerce-commercecheckoutordersummarywrapper {
  margin-bottom: 20px;
}

.w-commerce-commercecheckoutsummarylineitem {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
  -webkit-flex-direction: row;
  -ms-flex-direction: row;
  flex-direction: row;
  margin-bottom: 8px;
  -webkit-box-pack: justify;
  -webkit-justify-content: space-between;
  -ms-flex-pack: justify;
  justify-content: space-between;
}

.w-commerce-commercecheckoutordersummaryextraitemslistitem {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
  -webkit-flex-direction: row;
  -ms-flex-direction: row;
  flex-direction: row;
  margin-bottom: 8px;
  -webkit-box-pack: justify;
  -webkit-justify-content: space-between;
  -ms-flex-pack: justify;
  justify-content: space-between;
}

.w-commerce-commercecheckoutsummarytotal {
  font-weight: 700;
}

.w-commerce-commercecheckoutplaceorderbutton {
  background-color: #3898ec;
  border-bottom-left-radius: 3px;
  border-bottom-right-radius: 3px;
  border-top-left-radius: 3px;
  border-top-right-radius: 3px;
  border-bottom-width: 0px;
  border-left-width: 0px;
  border-right-width: 0px;
  border-top-width: 0px;
  color: #fff;
  cursor: pointer;
  padding-top: 9px;
  padding-bottom: 9px;
  padding-left: 15px;
  padding-right: 15px;
  text-decoration: none;
  display: block;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  margin-bottom: 20px;
  text-align: center;
}

.w-commerce-commercecheckouterrorstate {
  margin-top: 16px;
  margin-bottom: 16px;
  padding-top: 10px;
  padding-right: 16px;
  padding-bottom: 10px;
  padding-left: 16px;
  background-color: #ffdede;
}

.w-commerce-commercepaypalcheckoutformcontainer {
  width: 100%;
  min-height: 100vh;
  padding-top: 20px;
  padding-bottom: 20px;
  padding-right: 20px;
  padding-left: 20px;
  background-color: #f5f5f5;
}

.w-commerce-commercecheckoutcustomerinfosummarywrapper {
  margin-bottom: 20px;
}

.w-commerce-commercecheckoutsummaryitem {
  margin-bottom: 8px;
}

.w-commerce-commercecheckoutsummarylabel {
  margin-bottom: 8px;
}

.w-commerce-commercecheckoutsummaryflexboxdiv {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
  -webkit-flex-direction: row;
  -ms-flex-direction: row;
  flex-direction: row;
  -webkit-box-pack: start;
  -webkit-justify-content: flex-start;
  -ms-flex-pack: start;
  justify-content: flex-start;
}

.w-commerce-commercecheckoutsummarytextspacingondiv {
  margin-right: 0.33em;
}

.w-commerce-commercecheckoutpaymentsummarywrapper {
  margin-bottom: 20px;
}

.w-commerce-commercepaypalcheckouterrorstate {
  margin-top: 16px;
  margin-bottom: 16px;
  padding-top: 10px;
  padding-right: 16px;
  padding-bottom: 10px;
  padding-left: 16px;
  background-color: #ffdede;
}

.w-commerce-commerceorderconfirmationcontainer {
  width: 100%;
  min-height: 100vh;
  padding-top: 20px;
  padding-bottom: 20px;
  padding-right: 20px;
  padding-left: 20px;
  background-color: #f5f5f5;
}

.w-commerce-commercecheckoutshippingsummarywrapper {
  margin-bottom: 20px;
}

@media screen and (max-width: 767px) {
  .w-commerce-commercelayoutcontainer {
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -webkit-flex-direction: column;
    -ms-flex-direction: column;
    flex-direction: column;
    -webkit-box-align: stretch;
    -webkit-align-items: stretch;
    -ms-flex-align: stretch;
    align-items: stretch;
  }
  .w-commerce-commercelayoutmain {
    margin-right: 0px;
    -webkit-flex-basis: auto;
    -ms-flex-preferred-size: auto;
    flex-basis: auto;
  }
  .w-commerce-commercelayoutsidebar {
    -webkit-flex-basis: auto;
    -ms-flex-preferred-size: auto;
    flex-basis: auto;
  }
}

@media screen and (max-width: 479px) {
  .w-commerce-commerceaddtocartquantityinput {
    font-size: 16px;
  }
  .w-commerce-commercecheckoutemailinput {
    font-size: 16px;
  }
  .w-commerce-commercecheckoutshippingfullname {
    font-size: 16px;
  }
  .w-commerce-commercecheckoutshippingstreetaddress {
    font-size: 16px;
  }
  .w-commerce-commercecheckoutshippingstreetaddressoptional {
    font-size: 16px;
  }
  .w-commerce-commercecheckoutrow {
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -webkit-flex-direction: column;
    -ms-flex-direction: column;
    flex-direction: column;
  }
  .w-commerce-commercecheckoutshippingcity {
    font-size: 16px;
  }
  .w-commerce-commercecheckoutshippingstateprovince {
    font-size: 16px;
  }
  .w-commerce-commercecheckoutshippingzippostalcode {
    font-size: 16px;
  }
  .w-commerce-commercecheckoutshippingcountryselector {
    font-size: 16px;
  }
  .w-commerce-commercecheckoutcardnumber {
    font-size: 16px;
  }
  .w-commerce-commercecheckoutcardexpirationdate {
    font-size: 16px;
  }
  .w-commerce-commercecheckoutcardsecuritycode {
    font-size: 16px;
  }
  .w-commerce-commercecheckoutbillingfullname {
    font-size: 16px;
  }
  .w-commerce-commercecheckoutbillingstreetaddress {
    font-size: 16px;
  }
  .w-commerce-commercecheckoutbillingstreetaddressoptional {
    font-size: 16px;
  }
  .w-commerce-commercecheckoutbillingcity {
    font-size: 16px;
  }
  .w-commerce-commercecheckoutbillingstateprovince {
    font-size: 16px;
  }
  .w-commerce-commercecheckoutbillingzippostalcode {
    font-size: 16px;
  }
  .w-commerce-commercecheckoutbillingcountryselector {
    font-size: 16px;
  }
}
/*
body {
  padding-bottom: 96px;
  background-color: #12141d;
  color: hsla(0, 0%, 100%, 0.5);
  font-size: 16px;
  line-height: 24px;
  font-weight: 500;
} */

h1 {
  margin-top: 0px;
  /* margin-bottom: 24px; */
  color: #fff;
  font-size: 40px;
  line-height: 44px;
  font-weight: 600;
  letter-spacing: -0.02em;
}

h2 {
  margin-top: 0px;
  /* margin-bottom: 24px; */
  color: #fff;
  font-size: 32px;
  line-height: 40px;
  font-weight: 600;
  letter-spacing: -0.02em;
}

h3 {
  margin-top: 0px;
  /* margin-bottom: 24px; */
  color: #fff;
  font-size: 28px;
  line-height: 36px;
  font-weight: 600;
  letter-spacing: -0.01em;
}

h4 {
  margin-top: 0px;
  /* margin-bottom: 16px; */
  color: #fff;
  font-size: 24px;
  line-height: 30px;
  font-weight: 600;
  letter-spacing: 0em;
}

h5 {
  margin-top: 0px;
  /* margin-bottom: 12px; */
  color: #fff;
  font-size: 20px;
  line-height: 28px;
  font-weight: 600;
  letter-spacing: 0em;
}

h6 {
  margin-top: 0px;
  /* margin-bottom: 12px; */
  /* font-family: Inter, sans-serif; */
  /* color: #fff; */
  font-size: 14px;
  line-height: 24px;
  font-weight: bold;
  /* letter-spacing: 0em; */
}

/* p {
  margin-bottom: 24px;
} */

/* a {
  font-family: Inter, sans-serif;
  color: #fff;
  font-weight: 600;
  text-decoration: none;
} */

a:hover {
  cursor: pointer;
}

ul {
  margin-top: 0px;
  margin-bottom: 0px;
  padding-left: 24px;
}

img {
  display: inline-block;
  max-width: 100%;
}

blockquote {
  margin-top: 48px;
  margin-bottom: 48px;
  padding: 48px;
  border-left: 5px none #e2e2e2;
  border-radius: 12px;
  background-color: #8f2df0;
  color: #fff;
  font-size: 28px;
  line-height: 36px;
  font-weight: 600;
  letter-spacing: -0.01em;
}

figure {
  margin-bottom: 10px;
}

figcaption {
  margin-top: 12px;
  font-size: 14px;
  line-height: 22px;
  text-align: center;
}

.container {
  width: 100%;
  max-width: 900px;
  /* margin-right: auto; */
  /* margin-left: auto; */
  /* padding-right: 24px; */
  padding-left: 24px;
  perspective: 500px;
}

.container.align-content-center {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -webkit-flex-direction: column;
  -ms-flex-direction: column;
  flex-direction: column;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;

}
@media (min-width: 1024px) {
  .container.align-content-center {
  margin-top: 130px;
  }
}

.container.justify-content-center {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
  -webkit-justify-content: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
}

.section {
  margin-top: 20px;
  margin-bottom: 20px;
}

.section.section-small {
  margin-top: 96px;
  margin-bottom: 96px;
}

.section.no-top-space {
  margin-top: 0px;
}

.section.fullscreen-section {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  min-height: 100vh;
  margin-top: 0px;
  margin-bottom: 0px;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -webkit-flex-direction: column;
  -ms-flex-direction: column;
  flex-direction: column;
}

.section.hero-section {
  margin-top: 72px;
}

.section.demo-pages-section {
  margin-top: 0px;
  margin-bottom: 0px;
  padding-top: 144px;
  padding-bottom: 0px;
}

.section.top-section {
  position: relative;
  z-index: 2;
}

.rounded-image {
  border-radius: 12px;
}

.rounded-image.section-title-image {
  width: 100%;
  max-width: 612px;
  margin-left: 24px;
}

.rounded-image.slider-image {
  width: 100%;
}

.panel {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -webkit-flex-direction: column;
  -ms-flex-direction: column;
  flex-direction: column;
  -webkit-box-pack: justify;
  -webkit-justify-content: space-between;
  -ms-flex-pack: justify;
  justify-content: space-between;
  -webkit-box-align: start;
  -webkit-align-items: flex-start;
  -ms-flex-align: start;
  align-items: flex-start;
  border-radius: 12px;
  background-color: hsla(0, 0%, 100%, 0.05);
  direction: ltr;
}

.panel.style-guide-color-swatch {
  width: 96px;
  height: 96px;
  margin-bottom: 12px;
}

.panel.style-guide-color-swatch.bg-primary-3 {
  background-color: #3ccf91;
}

.panel.style-guide-color-swatch.bg-gray-1 {
  border-style: solid;
  border-width: 1px;
  border-color: hsla(0, 0%, 100%, 0.1);
}

.panel.style-guide-color-swatch.bg-gradient-6 {
  background-image: -webkit-gradient(linear, left top, right top, from(#3c5ccf), to(#ff715b));
  background-image: linear-gradient(90deg, #3c5ccf, #ff715b);
}

.panel.bg-gradient-6 {
  background-image: -webkit-gradient(linear, left top, right top, from(#3c5ccf), to(#ff715b));
  background-image: linear-gradient(90deg, #3c5ccf, #ff715b);
}

.panel.bg-gradient-7 {
  background-image: -webkit-gradient(linear, left top, right top, from(#ff715b), to(#5132c0));
  background-image: linear-gradient(90deg, #ff715b, #5132c0);
}

.panel.section {
  position: relative;
  margin-top: 24px;
  margin-bottom: 24px;
  padding-top: 108px;
  padding-bottom: 108px;
  border-radius: 12px;
}

.panel.section.cta-section {
  padding-bottom: 0px;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
}

.panel.section.hero-panel {
  min-height: 694px;
}

.panel.section.rounded {
  -webkit-justify-content: space-around;
  -ms-flex-pack: distribute;
  justify-content: space-around;
}

.panel.bg-white {
  color: #12141d;
}

.panel.bg-white.panel-on-image {
  position: absolute;
  right: 24px;
  bottom: 24px;
  max-width: 384px;
}

.panel.panel-wide {
  display: -ms-grid;
  display: grid;
  -webkit-box-align: stretch;
  -webkit-align-items: stretch;
  -ms-flex-align: stretch;
  align-items: stretch;
  grid-auto-columns: 1fr;
  grid-column-gap: 0px;
  grid-row-gap: 0px;
  -ms-grid-columns: 1fr 1fr;
  grid-template-columns: 1fr 1fr;
  -ms-grid-rows: auto;
  grid-template-rows: auto;
}

.panel.modal-panel {
  position: relative;
}

.panel.tab-panel {
  padding: 0px;
  -webkit-transition: color 200ms ease, background-color 200ms ease;
  transition: color 200ms ease, background-color 200ms ease;
  color: hsla(0, 0%, 100%, 0.5);
}

.panel.tab-panel:hover {
  background-color: hsla(0, 0%, 100%, 0.1);
}

.panel.tab-panel.w--current {
  background-color: hsla(0, 0%, 100%, 0.1);
  color: #fff;
}

.panel.wide-case-study-panel {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  overflow: hidden;
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
  -webkit-flex-direction: row;
  -ms-flex-direction: row;
  flex-direction: row;
  -webkit-box-pack: start;
  -webkit-justify-content: flex-start;
  -ms-flex-pack: start;
  justify-content: flex-start;
  -webkit-flex-wrap: nowrap;
  -ms-flex-wrap: nowrap;
  flex-wrap: nowrap;
  -webkit-box-align: stretch;
  -webkit-align-items: stretch;
  -ms-flex-align: stretch;
  align-items: stretch;
}

.panel.case-study-company-details-panel {
  z-index: 10;
}

.panel.grid-panel {
  height: 100%;
}

.panel.job-listing-list-item {
  -webkit-transition: background-color 200ms ease;
  transition: background-color 200ms ease;
  color: hsla(0, 0%, 100%, 0.5);
  font-weight: 500;
}

.panel.job-listing-list-item:hover {
  background-color: hsla(0, 0%, 100%, 0.1);
}

.panel.job-listing-title {
  position: -webkit-sticky;
  position: sticky;
  top: 104px;
}

.panel.rounded.bg-gray-5 {
  background-color: hsla(0, 0%, 100%, 0.2);
}

.panel.bg-primary-yellow {
  background-color: #ffe29a;
}

.panel.comics {
  background-color: rgba(0, 0, 0, 0.95);
}

.panel.comics-narration {
  background-color: #ffe29a;
  color: rgba(0, 0, 0, 0.5);
}

.card-heading {
  color: #fff;
  font-size: 32px;
  line-height: 40px;
  font-weight: 600;
  letter-spacing: -0.02em;
}

.card-heading.display-inline {
  color: hsla(0, 0%, 100%, 0.5);
}

.card-heading.display-inline.text-gray-4 {
  color: hsla(0, 0%, 100%, 0.5);
}

.card-heading.text-gray-4 {
  color: hsla(0, 0%, 100%, 0.5);
  text-align: center;
}

.text-white {
  color: #fff;
}

.panel-subheading {
  margin-bottom: 24px;
}

.panel-subheading.text-primary-3 {
  margin-bottom: 20px;
}

.button-outline {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  height: 48px;
  padding-right: 24px;
  padding-left: 24px;
  -webkit-box-pack: center;
  -webkit-justify-content: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  border-style: solid;
  border-width: 2px;
  border-color: hsla(0, 0%, 100%, 0.1);
  border-radius: 6px;
  background-color: transparent;
  -webkit-transition: border-color 200ms ease;
  transition: border-color 200ms ease;
  font-weight: 600;
}

.button-outline:hover {
  border-color: hsla(0, 0%, 100%, 0.5);
}

.heading {
  color: #fff;
}

.style-guide-container {
  display: -ms-grid;
  display: grid;
  grid-auto-columns: 1fr;
  grid-column-gap: 48px;
  grid-row-gap: 16px;
  -ms-grid-columns: 0.33fr 1fr;
  grid-template-columns: 0.33fr 1fr;
  -ms-grid-rows: auto;
  grid-template-rows: auto;
}

.style-guide-row {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  margin-bottom: 12px;
  -webkit-flex-wrap: wrap;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
}

.style-guide-content-item {
  margin-right: 24px;
  margin-bottom: 24px;
}

.bg-primary-1 {
  background-color: #ff715b;
}

.bg-primary-2 {
  background-color: #ff934f;
}

.bg-primary-3 {
  background-color: #3ccf91;
}

.bg-primary-4 {
  background-color: #3c5ccf;
}

.bg-primary-5 {
  background-color: #5132c0;
}

.bg-gray-1 {
  background-color: #12141d;
  color: #12141d;
}

.bg-gray-2 {
  background-color: hsla(0, 0%, 100%, 0.05);
  color: hsla(0, 0%, 100%, 0.05);
}

.bg-gray-3 {
  background-color: hsla(0, 0%, 100%, 0.1);
}

.bg-gray-4 {
  background-color: hsla(0, 0%, 100%, 0.5);
}

.bg-white {
  background-color: #fff;
}

.display-heading-1 {
  margin-bottom: 36px;
  font-family: Inter, sans-serif;
  color: #fff;
  font-size: 96px;
  line-height: 96px;
  font-weight: 700;
  letter-spacing: -0.02em;
}

.display-heading-2 {
  margin-top: 20px;
  margin-bottom: 20px;
  font-family: Inter, sans-serif;
  color: #fff;
  font-size: 88px;
  line-height: 88px;
  font-weight: 700;
  letter-spacing: -0.02em;
}

.display-heading-3 {
  margin-bottom: 24px;
  font-family: Inter, sans-serif;
  color: #fff;
  font-size: 72px;
  line-height: 72px;
  font-weight: 700;
  text-align: left;
  letter-spacing: -0.02em;
}

.display-heading-3.line-under {
  margin-bottom: 10px;
  padding-bottom: 20px;
  border-bottom: 1px solid hsla(0, 0%, 100%, 0.5);
}

.display-heading-4 {
  margin-bottom: 24px;
  font-family: Inter, sans-serif;
  color: #fff;
  font-size: 32px;
  line-height: 54px;
  font-weight: 700;
  letter-spacing: -0.02em;
}

.display-heading-4.space-bottom-large {
  margin-bottom: 48px;
}

.display-heading-4.space-bottom-small {
  margin-bottom: 12px;
}

.display-heading-4.space-bottom {
  margin-bottom: 36px;
}

.text-lead {
  font-family: Inter, sans-serif;
  font-size: 20px;
  line-height: 28px;
  font-weight: 500;
  letter-spacing: 0em;
}

.text-lead.horizontal-metric-text {
  max-width: 284px;
  margin-left: 24px;
  color: #fff;
}

.text-lead.blog-post-summary {
  margin-bottom: 96px;
}

.text-lead.job-listing-short-description {
  margin-bottom: 96px;
}

.row {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
}

.row.row-align-center {
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
  -webkit-flex-direction: row;
  -ms-flex-direction: row;
  flex-direction: row;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
}

.row.row-justify-between {
  -webkit-box-pack: justify;
  -webkit-justify-content: space-between;
  -ms-flex-pack: justify;
  justify-content: space-between;
}

.row.row-justify-between.align-content-center {
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
}

.row.content-width-extra-large {
  margin-right: auto;
  margin-left: auto;
}

.row.content-width-extra-large.large-text-row {
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
  -webkit-flex-direction: row;
  -ms-flex-direction: row;
  flex-direction: row;
}

.row.row-align-left {
  -webkit-align-self: flex-start;
  -ms-flex-item-align: start;
  align-self: flex-start;
}

.row.row-wrap {
  -webkit-flex-wrap: wrap;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
}

.lead-link {
  font-family: Inter, sans-serif;
  color: #fff;
  font-size: 20px;
  line-height: 28px;
  font-weight: 500;
  letter-spacing: 0em;
  text-decoration: none;
}

.text-small {
  font-family: Inter, sans-serif;
  color: hsla(0, 0%, 100%, 0.5);
  font-size: 14px;
  line-height: 22px;
  font-weight: 600;
  letter-spacing: 0em;
  text-decoration: none;
}

.text-small.form-hint-text {
  margin-top: 8px;
}

.text-small.form-subtext {
  margin-top: 24px;
}

.text-small.footer-2-subtext {
  text-align: right;
}

.text-small.search-result-url {
  margin-bottom: 24px;
}

.link-small {
  font-family: Inter, sans-serif;
  color: #fff;
  font-size: 14px;
  line-height: 24px;
  font-weight: 600;
  letter-spacing: 0em;
  text-decoration: none;
}

.style-guide-section {
  margin-top: 48px;
  margin-bottom: 48px;
}

.button-small {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  height: 36px;
  padding-right: 24px;
  padding-left: 24px;
  -webkit-box-pack: center;
  -webkit-justify-content: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  border-style: solid;
  border-width: 1px;
  border-color: #ff715b;
  border-radius: 6px;
  background-color: #ff715b;
  box-shadow: inset 0 0 20px 20px transparent;
  -webkit-transition: box-shadow 200ms ease;
  transition: box-shadow 200ms ease;
  font-size: 14px;
}

.button-small:hover {
  box-shadow: inset 0 0 20px 20px rgba(0, 0, 0, 0.1);
}

.button-large-outline {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  height: 60px;
  margin-right: 0px;
  padding-right: 36px;
  padding-left: 36px;
  -webkit-box-pack: center;
  -webkit-justify-content: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  border-style: solid;
  border-width: 2px;
  border-color: hsla(0, 0%, 100%, 0.1);
  border-radius: 6px;
  background-color: transparent;
  -webkit-transition: border-color 200ms ease;
  transition: border-color 200ms ease;
}

.button-large-outline:hover {
  border-color: hsla(0, 0%, 100%, 0.5);
}

.button-small-outline {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  height: 36px;
  padding-right: 24px;
  padding-left: 24px;
  -webkit-box-pack: center;
  -webkit-justify-content: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  border-style: solid;
  border-width: 2px;
  border-color: hsla(0, 0%, 100%, 0.1);
  border-radius: 6px;
  background-color: transparent;
  -webkit-transition: border-color 200ms ease;
  transition: border-color 200ms ease;
  font-size: 14px;
}

.button-small-outline:hover {
  border-color: hsla(0, 0%, 100%, 0.5);
}

.button {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  height: 48px;
  padding-right: 24px;
  padding-left: 24px;
  -webkit-box-pack: center;
  -webkit-justify-content: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  border-radius: 6px;
  background-color: #ff715b;
  box-shadow: inset 0 0 20px 20px transparent;
  -webkit-transition: box-shadow 200ms ease;
  transition: box-shadow 200ms ease;
  font-weight: 600;
}

.button:hover {
  box-shadow: inset 0 0 20px 20px rgba(0, 0, 0, 0.1);
}

.button.icon-button.bg-gray-1 {
  background-color: #12141d;
}

.button.search-form-button {
  position: absolute;
  right: 6px;
  padding-right: 16px;
  padding-left: 16px;
  background-color: #3c5ccf;
  font-weight: 600;
}

.button-large {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  height: 60px;
  margin-right: 0px;
  margin-bottom: 0px;
  padding-right: 36px;
  padding-left: 36px;
  -webkit-box-pack: center;
  -webkit-justify-content: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  border-radius: 6px;
  background-color: #ff715b;
  box-shadow: inset 0 0 20px 20px transparent;
  -webkit-transition: box-shadow 200ms ease;
  transition: box-shadow 200ms ease;
  font-weight: 600;
  text-align: center;
}

.button-large:hover {
  box-shadow: inset 0 0 20px 20px rgba(0, 0, 0, 0.1);
}

.button-large.bg-primary-3 {
  background-color: #3ccf91;
}

.button-large.bg-primary-2 {
  background-color: #ff934f;
}

.button-large.bg-primary-4 {
  background-color: #3c5ccf;
}

.button-large.bg-primary-5 {
  background-color: #5132c0;
}

.button-circle-small {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  width: 36px;
  height: 36px;
  -webkit-box-pack: center;
  -webkit-justify-content: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  border-radius: 50%;
  background-color: #ff715b;
}

.button-circle-small:hover {
  opacity: 0.75;
}

.button-circle-small.bg-gray-3 {
  background-color: hsla(0, 0%, 100%, 0.1);
}

.icon-circle-small {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  width: 36px;
  height: 36px;
  -webkit-box-pack: center;
  -webkit-justify-content: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-flex: 0;
  -webkit-flex: 0 0 auto;
  -ms-flex: 0 0 auto;
  flex: 0 0 auto;
  border-radius: 50%;
  background-color: #ff715b;
}

.icon-circle-small.bg-gray-3 {
  background-color: hsla(0, 0%, 100%, 0.1);
}

.button-circle-small-icon {
  width: 16px;
  height: 16px;
}

.button-circle {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  width: 48px;
  height: 48px;
  -webkit-box-pack: center;
  -webkit-justify-content: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  border-radius: 50%;
  background-color: #ff715b;
}

.button-circle:hover {
  opacity: 0.75;
}

.button-circle.bg-gray-3 {
  background-color: hsla(0, 0%, 100%, 0.1);
}

.button-circle.bg-white {
  background-color: #fff;
}

.button-circle-icon {
  width: 20px;
  height: 20px;
}

.button-circle-large {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  width: 60px;
  height: 60px;
  -webkit-box-pack: center;
  -webkit-justify-content: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  border-radius: 50%;
  background-color: #ff715b;
}

.button-circle-large:hover {
  opacity: 0.75;
}

.button-circle-large-icon {
  width: 24px;
  height: 24px;
}

.icon-button {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  height: 48px;
  padding-right: 24px;
  padding-left: 12px;
  -webkit-box-pack: center;
  -webkit-justify-content: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  border-radius: 6px;
  background-color: #ff715b;
}

.icon-button-text {
  color: #fff;
}

.icon-button-icon {
  width: 24px;
  height: 24px;
  margin-right: 12px;
}

.dropdown-toggle {
  position: relative;
  align-items: center;
  border-radius: 6px;
  background-color: whote;
  color: #75768b;
  /* font-weight: 600; */
  border: 1px solid #9a929266;
}

.dropdown-toggle:hover {
  box-shadow: inset 0 0 20px 20px rgba(0, 0, 0, 0.1);
}

.dropdown-toggle.button-large {
  height: 60px;
}

.dropdown-icon {
  width: 6px;
  height: 5px;
  margin-left: 6px;
}

.form-input {
  height: 48px;
  margin-bottom: 0px;
  padding-right: 20px;
  padding-left: 20px;
  border: 1px none #000;
  border-radius: 6px;
  background-color: hsla(0, 0%, 100%, 0.1);
  -webkit-transition: background-color 200ms ease;
  transition: background-color 200ms ease;
  color: #fff;
  font-size: 16px;
  line-height: 24px;
}

.form-input:focus {
  background-color: hsla(0, 0%, 100%, 0.25);
  color: #fff;
}

.form-input::-webkit-input-placeholder {
  color: hsla(0, 0%, 100%, 0.5);
}

.form-input:-ms-input-placeholder {
  color: hsla(0, 0%, 100%, 0.5);
}

.form-input::-ms-input-placeholder {
  color: hsla(0, 0%, 100%, 0.5);
}

.form-input::placeholder {
  color: hsla(0, 0%, 100%, 0.5);
}

.form-input.form-input-large {
  height: 70px;
  padding-right: 24px;
  padding-left: 24px;
}

.form-input.form-input-large.search-form-input {
  margin-right: 12px;
}

.form-input.form-input-large.search-modal-input {
  padding-right: 96px;
}

.form-input.form-input-small {
  height: 36px;
  padding-left: 16px;
  cursor: pointer;
}

.form-input.textarea-small {
  height: 168px;
  padding-left: 16px;
}

.form-input.textarea {
  height: 168px;
}

.form-input.textarea-large {
  height: 168px;
  padding-left: 24px;
}

.field-label {
  margin-bottom: 12px;
  color: #fff;
  font-size: 16px;
  line-height: 24px;
  font-weight: 500;
}

.checkbox {
  width: 16px;
  height: 16px;
  margin-top: 0px;
  margin-left: 0px;
  -webkit-box-flex: 0;
  -webkit-flex: 0 0 auto;
  -ms-flex: 0 0 auto;
  flex: 0 0 auto;
  border-style: none;
  border-radius: 2px;
  background-color: hsla(0, 0%, 100%, 0.1);
  -webkit-transition: background-color 200ms ease;
  transition: background-color 200ms ease;
  cursor: pointer;
}

.checkbox.w--redirected-checked {
  height: 16px;
  border-style: none;
  border-radius: 2px;
  background-color: #ff715b;
  background-image: url('https://assets.tinyview.com/assets/images/icon-check_1.svg');
  background-size: auto;
  background-repeat: no-repeat;
}

.checkbox-label {
  margin-bottom: 0px;
  margin-left: 12px;
}

.checkbox-field {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  margin-right: 24px;
  margin-bottom: 24px;
  padding-left: 0px;
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
  -webkit-flex-direction: row;
  -ms-flex-direction: row;
  flex-direction: row;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
}

.checkbox-field.switch {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
  -webkit-flex-direction: row;
  -ms-flex-direction: row;
  flex-direction: row;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
}

.checkbox-field.checkbox-field-horizontal {
  margin-bottom: 0px;
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
  -webkit-flex-direction: row;
  -ms-flex-direction: row;
  flex-direction: row;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
}

.radio-button {
  width: 16px;
  height: 16px;
  margin-bottom: 8px;
  margin-left: 0px;
  border-style: solid;
  border-width: 0px;
  border-color: #ff715b;
  background-color: hsla(0, 0%, 100%, 0.1);
  -webkit-transition: border-width 200ms ease, border-color 200ms ease, background-color 200ms ease;
  transition: border-width 200ms ease, border-color 200ms ease, background-color 200ms ease;
  cursor: pointer;
}

.radio-button.w--redirected-checked {
  border-style: solid;
  border-width: 5px;
  border-color: #ff715b;
  background-color: #fff;
  -webkit-transition: background-color 200ms ease, background-color 200ms ease, border-width 200ms ease;
  transition: background-color 200ms ease, background-color 200ms ease, border-width 200ms ease;
}

.radio-button-field {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  margin-right: 24px;
  margin-bottom: 24px;
  padding-left: 0px;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -webkit-flex-direction: column;
  -ms-flex-direction: column;
  flex-direction: column;
  -webkit-box-align: start;
  -webkit-align-items: flex-start;
  -ms-flex-align: start;
  align-items: flex-start;
}

.radio-button-label {
  color: #fff;
}

.checkbox-switch {
  width: 36px;
  height: 20px;
  margin-top: 0px;
  margin-bottom: 0px;
  margin-left: 0px;
  border-style: none;
  border-radius: 20px;
  background-color: hsla(0, 0%, 100%, 0.5);
  background-image: url('https://assets.tinyview.com/assets/images/checkbox-switch-handle.svg');
  background-position: 18% 50%;
  background-size: 12px 12px;
  background-repeat: no-repeat;
  opacity: 0.5;
  -webkit-transition: background-position 200ms ease, background-color 200ms ease, opacity 200ms ease;
  transition: background-position 200ms ease, background-color 200ms ease, opacity 200ms ease;
  cursor: pointer;
}

.checkbox-switch.w--redirected-checked {
  background-color: #ff715b;
  background-image: url('https://assets.tinyview.com/assets/images/checkbox-switch-handle.svg');
  background-position: 82% 50%;
  background-size: 12px 12px;
  opacity: 1;
}

.badge {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  height: 32px;
  min-width: 32px;
  padding-right: 12px;
  padding-left: 12px;
  -webkit-box-pack: center;
  -webkit-justify-content: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  border-radius: 16px;
  background-color: #ff715b;
  box-shadow: inset 0 20px 20px 20px transparent;
  -webkit-transition: box-shadow 200ms ease;
  transition: box-shadow 200ms ease;
  color: #fff;
  font-size: 16px;
  line-height: 24px;
  font-weight: 600;
}

.badge:hover {
  box-shadow: inset 0 20px 20px 20px rgba(0, 0, 0, 0.15);
}

.badge.bg-primary-2 {
  background-color: #ff934f;
}

.badge.bg-primary-5 {
  background-color: #5132c0;
}

.badge.bg-primary-3 {
  background-color: #3ccf91;
}

.badge.bg-primary-4 {
  background-color: #3c5ccf;
}

.badge.blog-small-badge {
  margin-bottom: 12px;
}

.panel-body {
  position: relative;
  z-index: 1;
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  width: 100%;
  margin-right: auto;
  margin-left: auto;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -webkit-flex-direction: column;
  -ms-flex-direction: column;
  flex-direction: column;
  -webkit-box-pack: center;
  -webkit-justify-content: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: stretch;
  -webkit-align-items: stretch;
  -ms-flex-align: stretch;
  align-items: stretch;
  -webkit-box-flex: 1;
  -webkit-flex: 1 0 auto;
  -ms-flex: 1 0 auto;
  flex: 1 0 auto;
  border-radius: 0px;
}

.panel-body.justify-content-center {
  -webkit-box-pack: center;
  -webkit-justify-content: center;
  -ms-flex-pack: center;
  justify-content: center;
}

.panel-body.panel-body-small {
  padding: 24px;
}

.panel-body.panel-body-large {
  padding: 48px;
}

.panel-body.panel-body-large.align-content-center {
  padding-top: 48px;
  padding-bottom: 48px;
}

.panel-body.align-content-center {
  -webkit-box-pack: center;
  -webkit-justify-content: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
}

.panel-body.panel-body-extra-small {
  padding: 12px 24px;
}

.panel-body.footer-1-panel-body {
  padding: 72px 72px 48px;
}

.panel-body.footer-2-panel-body {
  display: -ms-grid;
  display: grid;
  padding: 48px;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  grid-auto-columns: 1fr;
  grid-column-gap: 24px;
  grid-row-gap: 24px;
  -ms-grid-columns: 1fr 1fr 1fr;
  grid-template-columns: 1fr 1fr 1fr;
  -ms-grid-rows: auto;
  grid-template-rows: auto;
}

.panel-body.blog-small-panel-body {
  -webkit-box-pack: start;
  -webkit-justify-content: flex-start;
  -ms-flex-pack: start;
  justify-content: flex-start;
  -webkit-box-align: start;
  -webkit-align-items: flex-start;
  -ms-flex-align: start;
  align-items: flex-start;
}

.panel-body.grid-halves {
  display: -ms-grid;
  display: grid;
  grid-auto-columns: 1fr;
  grid-column-gap: 36px;
  grid-row-gap: 36px;
  -ms-grid-columns: 1fr 1fr;
  grid-template-columns: 1fr 1fr;
  -ms-grid-rows: auto;
  grid-template-rows: auto;
}

.panel-body.modal-panel-body {
  border-radius: 12px;
  background-color: hsla(0, 0%, 100%, 0.05);
}

.panel-body.accordion {
  padding: 0px;
}

.panel-body.wide-case-study-panel-body {
  max-width: 35%;
  -webkit-box-flex: 0;
  -webkit-flex: 0 0 auto;
  -ms-flex: 0 0 auto;
  flex: 0 0 auto;
}

.circle-large {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  width: 96px;
  height: 96px;
  margin-bottom: 24px;
  -webkit-box-pack: center;
  -webkit-justify-content: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-flex: 0;
  -webkit-flex: 0 0 auto;
  -ms-flex: 0 0 auto;
  flex: 0 0 auto;
  border-radius: 48px;
  background-color: hsla(0, 0%, 100%, 0.1);
}

.circle-large.bg-primary-2 {
  background-color: #ff934f;
}

.circle-large.bg-primary-3 {
  background-color: #3ccf91;
}

.circle-large.bg-primary-4 {
  background-color: #3c5ccf;
}

.circle-large.bg-primary-5 {
  background-color: #5132c0;
}

.circle-large.circle-horizontal {
  margin-right: 24px;
  margin-bottom: 0px;
}

.circle-large.bg-gray-3 {
  background-color: hsla(0, 0%, 100%, 0.1);
}

.circle-large.circle-small {
  width: 36px;
  height: 36px;
}

.circle-large.circle-small.bg-gray-3 {
  margin-bottom: 0px;
}

.circle-large.bg-primary-1 {
  background-color: #ff715b;
}

.circle-large.pricing-panel-circle {
  margin-bottom: 0px;
  margin-left: 24px;
}

.circle-large.video-circle-large {
  position: absolute;
  margin-bottom: 0px;
  background-color: #fff;
  -webkit-transition: -webkit-transform 200ms ease;
  transition: -webkit-transform 200ms ease;
  transition: transform 200ms ease;
  transition: transform 200ms ease, -webkit-transform 200ms ease;
}

.circle-large.video-circle-large:hover {
  -webkit-transform: scale(0.9);
  -ms-transform: scale(0.9);
  transform: scale(0.9);
}

.grid-halves {
  display: -ms-grid;
  display: grid;
  grid-auto-columns: 1fr;
  grid-column-gap: 24px;
  grid-row-gap: 24px;
  -ms-grid-columns: 1fr 1fr;
  grid-template-columns: 1fr 1fr;
  -ms-grid-rows: auto;
  grid-template-rows: auto;
}

.bg-gradient-1 {
  background-image: -webkit-gradient(linear, left top, right top, from(#ff715b), to(#ff934f));
  background-image: linear-gradient(90deg, #ff715b, #ff934f);
}

.bg-gradient-7 {
  background-image: -webkit-gradient(linear, left top, right top, from(#ff715b), to(#5132c0));
  background-image: linear-gradient(90deg, #ff715b, #5132c0);
}

.bg-gradient-3 {
  background-image: -webkit-gradient(linear, left top, right top, from(#3ccf91), to(#3c5ccf));
  background-image: linear-gradient(90deg, #3ccf91, #3c5ccf);
}

.bg-gradient-4 {
  background-image: -webkit-gradient(linear, left top, right top, from(#3c5ccf), to(#5132c0));
  background-image: linear-gradient(90deg, #3c5ccf, #5132c0);
}

.bg-gradient-9 {
  background-image: -webkit-gradient(linear, left top, right top, from(#8f2df0), to(#ff934f));
  background-image: linear-gradient(90deg, #8f2df0, #ff934f);
}

.bg-gradient-6 {
  background-image: -webkit-gradient(linear, left top, right top, from(#3ccf91), to(#3c5ccf));
  background-image: linear-gradient(90deg, #3ccf91, #3c5ccf);
}

.bg-gradient-8 {
  background-image: -webkit-gradient(linear, left top, right top, from(#8f2df0), to(#3c5ccf));
  background-image: linear-gradient(90deg, #8f2df0, #3c5ccf);
}

.bg-primary-6 {
  background-color: #8f2df0;
}

.bg-gradient-2 {
  background-image: -webkit-gradient(linear, left top, right top, from(#ff934f), to(#3ccf91));
  background-image: linear-gradient(90deg, #ff934f, #3ccf91);
}

.bg-gradient-5 {
  background-image: -webkit-gradient(linear, left top, right top, from(#5132c0), to(#8f2df0));
  background-image: linear-gradient(90deg, #5132c0, #8f2df0);
}

.bg-gradient-6-reverse {
  background-image: -webkit-gradient(linear, left top, right top, from(#ff715b), to(#3c5ccf));
  background-image: linear-gradient(90deg, #ff715b, #3c5ccf);
}

.grid-thirds {
  display: -ms-grid;
  display: grid;
  grid-auto-columns: 1fr;
  grid-column-gap: 24px;
  grid-row-gap: 24px;
  -ms-grid-columns: 1fr 1fr 1fr;
  grid-template-columns: 1fr 1fr 1fr;
  -ms-grid-rows: auto;
  grid-template-rows: auto;
}

.grid-thirds.space-bottom {
  margin-bottom: 0px;
  -ms-grid-columns: 1fr 1fr 1fr;
  grid-template-columns: 1fr 1fr 1fr;
}

.display-inline {
  display: inline;
}

.space-bottom {
  margin-bottom: 24px;
}

.center-content {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -webkit-flex-direction: column;
  -ms-flex-direction: column;
  flex-direction: column;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  text-align: center;
}

.center-content.content-width-large {
  text-align: left;
}

.no-bottom-space {
  margin-bottom: 0px;
  padding-bottom: 0px;
}

.no-bottom-space.space-left {
  margin-left: 24px;
}

.no-bottom-space.no-bottom-border {
  border-bottom-style: none;
}

.bordered-list-item {
  margin-bottom: 24px;
  padding-bottom: 24px;
  border-bottom: 1px solid hsla(0, 0%, 100%, 0.1);
}

.content-width-small {
  width: 100%;
  max-width: 400px;
}

.content-width-medium {
  width: 100%;
  max-width: 612px;
}

.content-width-medium.article-title-wrapper {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  max-width: 728px;
  margin-right: auto;
  margin-bottom: 96px;
  margin-left: auto;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -webkit-flex-direction: column;
  -ms-flex-direction: column;
  flex-direction: column;
  -webkit-box-align: start;
  -webkit-align-items: flex-start;
  -ms-flex-align: start;
  align-items: flex-start;
}

.content-width-medium.modal-content {
  position: relative;
  z-index: 2;
}

.content-width-large {
  width: 100%;
  max-width: 824px;
}

.content-width-extra-large {
  width: 100%;
  max-width: 964px;
  margin-right: auto;
  margin-left: auto;
}

.large-heading {
  color: #fff;
  font-size: 40px;
  line-height: 44px;
  font-weight: 600;
  letter-spacing: -0.02em;
}

.text-gray-4 {
  color: hsla(0, 0%, 100%, 0.5);
}

.text-primary-1 {
  color: #ff715b;
}

.bordered-list-item-small {
  margin-bottom: 12px;
  padding-bottom: 12px;
  border-bottom: 1px solid hsla(0, 0%, 100%, 0.1);
}

.icon-small {
  width: 18px;
}

.space-left-small {
  margin-left: 12px;
}

.circle-small {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  width: 36px;
  height: 36px;
  -webkit-box-pack: center;
  -webkit-justify-content: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-flex: 0;
  -webkit-flex: 0 0 auto;
  -ms-flex: 0 0 auto;
  flex: 0 0 auto;
  border-radius: 18px;
  background-color: hsla(0, 0%, 100%, 0.1);
}

.circle {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  width: 72px;
  height: 72px;
  -webkit-box-pack: center;
  -webkit-justify-content: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-flex: 0;
  -webkit-flex: 0 0 auto;
  -ms-flex: 0 0 auto;
  flex: 0 0 auto;
  border-radius: 36px;
  background-color: hsla(0, 0%, 100%, 0.1);
}

.circle.circle-horizontal {
  margin-right: 24px;
}

.icon {
  width: 36px;
}

.text-primary-3 {
  color: #3ccf91;
}

.text-primary-2 {
  color: #ff934f;
}

.text-primary-4 {
  color: #3c5ccf;
}

.list-grid {
  display: -ms-grid;
  display: grid;
  grid-auto-columns: 1fr;
  grid-column-gap: 0px;
  grid-row-gap: 12px;
  -ms-grid-columns: 1fr;
  grid-template-columns: 1fr;
  -ms-grid-rows: auto;
  grid-template-rows: auto;
}

.space-bottom-large {
  margin-bottom: 36px;
}

.grid-two-thirds {
  justify-items: stretch;
  -webkit-box-align: start;
  -webkit-align-items: start;
  -ms-flex-align: start;
  align-items: start;
  grid-auto-flow: row;
  grid-column-gap: 24px;
  grid-row-gap: 24px;
  -ms-grid-columns: 2fr 1fr;
  grid-template-columns: 2fr 1fr;
  -ms-grid-rows: auto;
  grid-template-rows: auto;
}

.grid-two-thirds.align-top {
  -webkit-box-align: start;
  -webkit-align-items: start;
  -ms-flex-align: start;
  align-items: start;
}

.grid-two-thirds.reverse {
  direction: rtl;
}

.grid-two-thirds.reverse.panel-height-large {
  -ms-grid-columns: 1fr;
  grid-template-columns: 1fr;
}

.space-left-extra-small {
  margin-left: 6px;
}

.section-title {
  margin-bottom: 40px;
}

.timeline-pin-head {
  width: 24px;
  height: 24px;
  border-style: solid;
  border-width: 2px;
  border-color: #ff934f;
  border-radius: 12px;
}

.timeline-pin-line {
  width: 13px;
  height: 37px;
  border-right: 2px solid #ff934f;
}

.space-left {
  margin-left: 24px;
}

.circle-extra-large {
  width: 120px;
  height: 120px;
  border-radius: 60px;
}

.space-bottom-extra-small {
  margin-bottom: 6px;
}

.panel-body-large {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  padding: 48px;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -webkit-flex-direction: column;
  -ms-flex-direction: column;
  flex-direction: column;
  -webkit-box-pack: justify;
  -webkit-justify-content: space-between;
  -ms-flex-pack: justify;
  justify-content: space-between;
}

.panel-body-small {
  padding: 24px;
}

.quote-on-image {
  position: relative;
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: start;
  -webkit-justify-content: flex-start;
  -ms-flex-pack: start;
  justify-content: flex-start;
  -webkit-box-align: start;
  -webkit-align-items: flex-start;
  -ms-flex-align: start;
  align-items: flex-start;
}

.rounded {
  border-radius: 12px;
}

.avatar {
  width: 48px;
  height: 48px;
  -webkit-box-flex: 0;
  -webkit-flex: 0 0 auto;
  -ms-flex: 0 0 auto;
  flex: 0 0 auto;
  border-radius: 50%;
}

.avatar.avatar-large {
  width: 96px;
  height: 96px;
}

.avatar-row {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
}

.avatar-row-text {
  margin-left: 12px;
}

.text-muted {
  opacity: 0.5;
}

.panel-wide {
  grid-column-gap: 0px;
  grid-row-gap: 0px;
  -ms-grid-rows: auto;
  grid-template-rows: auto;
}

.link-arrow {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
}

.row-inline {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
}

.brand-row {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
  -webkit-justify-content: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-flex-wrap: wrap;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
}

.brand-row.space-bottom-large {
  margin-bottom: 48px;
  -webkit-flex-wrap: wrap;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
}

.brand-row.align-content-left {
  margin-left: -24px;
  -webkit-box-pack: start;
  -webkit-justify-content: flex-start;
  -ms-flex-pack: start;
  justify-content: flex-start;
}

.brand-image {
  margin: 12px 24px;
  opacity: 0.5;
}

.nav-link {
  margin-right: 12px;
  margin-left: 12px;
  padding: 0px;
  opacity: 0.5;
  -webkit-transition: opacity 200ms ease;
  transition: opacity 200ms ease;
  color: #fff;
  font-weight: 600;
}

.nav-link:hover {
  opacity: 1;
}

.nav-link.w--current {
  color: #fff;
}

.navbar {
  padding-top: 16px;
  padding-bottom: 16px;
  -webkit-box-pack: justify;
  -webkit-justify-content: space-between;
  -ms-flex-pack: justify;
  justify-content: space-between;
  background-color: transparent;
}

.navbar-row {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: justify;
  -webkit-justify-content: space-between;
  -ms-flex-pack: justify;
  justify-content: space-between;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
}

.navbar-controls {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-justify-content: space-around;
  -ms-flex-pack: distribute;
  justify-content: space-around;
}

.navbar-1-brand {
  margin-right: 24px;
}

.dropdown-list {
  overflow: hidden;
  border-radius: 12px;
  background-color: #12141d;
}

.dropdown-list.w--open {
  padding: 0px;
  border-radius: 12px;
  box-shadow: 0 12px 12px 0 rgba(0, 0, 0, 0.2);
}

.dropdown-bg {
  position: absolute;
  left: 0%;
  top: 0%;
  right: 0%;
  bottom: 0%;
  background-color: hsla(0, 0%, 100%, 0.05);
}

.dropdown-item {
  position: relative;
  z-index: 1;
  width: 100%;
  min-width: 192px;
  padding: 6px 12px;
  border-radius: 6px;
  -webkit-transition: color 200ms ease, background-color 200ms ease;
  transition: color 200ms ease, background-color 200ms ease;
  color: hsla(0, 0%, 100%, 0.5);
}

.dropdown-item:hover {
  background-color: hsla(0, 0%, 100%, 0.05);
  color: #fff;
}

.dropdown-item.w--current {
  color: #fff;
}

.dropdown-item.dropdown-item-large {
  min-width: 250px;
  padding: 16px;
  border-radius: 12px;
  color: hsla(0, 0%, 100%, 0.5);
  font-weight: 500;
}

.dropdown-item-heading {
  margin-bottom: 4px;
}

.navbar-wrapper {
  position: relative;
  z-index: 5;
  background-color: #12141d;
}

@media (min-width: 1024px) {
.navbar-wrapper.sticky-top {
  position: fixed;
  top: 0;
  z-index: 99;
  width: 100%;
  /* margin-left: 287px; */
  height: 75px;
  border-bottom: 1px solid #e7e5df;
}
}
@media (max-width: 991px) {
.navbar-wrapper.sticky-top {
  display: block;
  top: 0px;
  z-index: 99;
  width: 100%;
  height: 62px;
  border-bottom: 1px solid #e7e5df;
}
}
.nav-menu {
  position: relative;
  height: 100%;
}

.dropdown-link-icon {
  margin-left: 4px;
}

.footer {
  margin-top: 24px;
}

.footer-1-top-row {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  margin-bottom: 96px;
  -webkit-box-pack: justify;
  -webkit-justify-content: space-between;
  -ms-flex-pack: justify;
  justify-content: space-between;
}

.content-width-extra-small {
  width: 100%;
  max-width: 328px;
}

.vertical-menu-grid {
  justify-items: start;
  grid-column-gap: 0px;
  grid-row-gap: 12px;
  -ms-grid-columns: 1fr;
  grid-template-columns: 1fr;
  -ms-grid-rows: auto;
  grid-template-rows: auto;
}

.footer-1-bottom-row {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: justify;
  -webkit-justify-content: space-between;
  -ms-flex-pack: justify;
  justify-content: space-between;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
}

.footer-menu-grids {
  display: -ms-grid;
  display: grid;
  -webkit-box-align: start;
  -webkit-align-items: start;
  -ms-flex-align: start;
  align-items: start;
  grid-auto-columns: 1fr;
  grid-column-gap: 72px;
  grid-row-gap: 16px;
  -ms-grid-columns: 1fr 1fr;
  grid-template-columns: 1fr 1fr;
  -ms-grid-rows: auto;
  grid-template-rows: auto;
}

.text-link {
  opacity: 0.5;
  -webkit-transition: opacity 200ms ease;
  transition: opacity 200ms ease;
}

.text-link:hover {
  opacity: 1;
}

.text-link.w--current {
  opacity: 1;
}

.social-list {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  margin-right: -12px;
  margin-left: -12px;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  grid-auto-columns: 1fr;
  grid-column-gap: 0px;
  grid-row-gap: 0px;
  -ms-grid-columns: 1fr 1fr;
  grid-template-columns: 1fr 1fr;
  -ms-grid-rows: auto auto;
  grid-template-rows: auto auto;
}

.social-link-item {
  margin-right: 12px;
  margin-left: 12px;
  -webkit-transition: opacity 200ms ease;
  transition: opacity 200ms ease;
}

.social-link-item:hover {
  opacity: 0.5;
}

.navbar-buttons {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
}

.form-vertical {
  display: -ms-grid;
  display: grid;
  grid-auto-columns: 1fr;
  grid-column-gap: 12px;
  grid-row-gap: 12px;
  grid-template-areas: ".";
  -ms-grid-columns: 1fr;
  grid-template-columns: 1fr;
  -ms-grid-rows: auto 12px auto;
  grid-template-rows: auto auto;
}

.form-grid-halves {
  grid-column-gap: 12px;
  grid-row-gap: 12px;
  -ms-grid-rows: auto;
  grid-template-rows: auto;
}

.horizontal-form {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
}

.horizontal-form-button {
  margin-left: 12px;
}

.form {
  width: 100%;
  margin-bottom: 0px;
}

.form-success {
  padding: 12px;
  border-radius: 6px;
  background-color: #3ccf91;
  color: #fff;
  text-align: center;
}

.form-error {
  padding: 12px;
  border-radius: 6px;
  background-color: #ff715b;
  color: #fff;
  text-align: center;
}

.left-content {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -webkit-flex-direction: column;
  -ms-flex-direction: column;
  flex-direction: column;
  -webkit-box-align: start;
  -webkit-align-items: flex-start;
  -ms-flex-align: start;
  align-items: flex-start;
}

.form-thirds {
  display: -ms-grid;
  display: grid;
  grid-auto-columns: 1fr;
  grid-column-gap: 12px;
  grid-row-gap: 12px;
  -ms-grid-columns: 1fr 1fr 1fr;
  grid-template-columns: 1fr 1fr 1fr;
  -ms-grid-rows: auto;
  grid-template-rows: auto;
}

.metric-row {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: justify;
  -webkit-justify-content: space-between;
  -ms-flex-pack: justify;
  justify-content: space-between;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
}

.vertical-text-grid {
  display: -ms-grid;
  display: grid;
  grid-auto-columns: 1fr;
  grid-column-gap: 48px;
  grid-row-gap: 48px;
  -ms-grid-columns: 1fr;
  grid-template-columns: 1fr;
  -ms-grid-rows: auto auto;
  grid-template-rows: auto auto;
}

.cta-row-1 {
  display: -ms-grid;
  display: grid;
  margin-right: auto;
  margin-left: auto;
  padding-right: 24px;
  padding-left: 24px;
  -webkit-justify-content: space-around;
  -ms-flex-pack: distribute;
  justify-content: space-around;
  justify-items: center;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  grid-auto-columns: 1fr;
  grid-column-gap: 48px;
  grid-row-gap: 48px;
  -ms-grid-columns: 1fr 0.5fr;
  grid-template-columns: 1fr 0.5fr;
  -ms-grid-rows: auto;
  grid-template-rows: auto;
}

.display-inline-block {
  display: inline-block;
}

.cta-row-2 {
  display: -ms-grid;
  display: grid;
  margin-right: auto;
  margin-left: auto;
  grid-auto-columns: 1fr;
  grid-column-gap: 48px;
  grid-row-gap: 48px;
  -ms-grid-columns: 2.5fr 2.5fr;
  grid-template-columns: 2.5fr 2.5fr;
  -ms-grid-rows: auto;
  grid-template-rows: auto;
}

.small-heading {
  font-size: 24px;
  line-height: 30px;
}

.small-heading.search-result-heading {
  margin-bottom: 6px;
}

.pricing-panel-top-row {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  margin-bottom: 24px;
  -webkit-box-pack: justify;
  -webkit-justify-content: space-between;
  -ms-flex-pack: justify;
  justify-content: space-between;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
}

.pricing-panel-price-row {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  margin-bottom: 24px;
  -webkit-box-pack: justify;
  -webkit-justify-content: space-between;
  -ms-flex-pack: justify;
  justify-content: space-between;
  -webkit-box-align: end;
  -webkit-align-items: flex-end;
  -ms-flex-align: end;
  align-items: flex-end;
}

.pricing-panel-terms {
  margin-left: 34px;
}

.pricing-panel-2-top-row {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  margin-bottom: 48px;
  -webkit-box-pack: justify;
  -webkit-justify-content: space-between;
  -ms-flex-pack: justify;
  justify-content: space-between;
}

.background-image-wrapper {
  position: absolute;
  left: 0%;
  top: 0%;
  right: 0%;
  bottom: 0%;
  z-index: 0;
}

.hero-bg-1 {
  position: absolute;
  left: 0%;
  top: 0%;
  right: 0%;
  bottom: 0%;
  border-radius: 12px;
  /* background-image: url('../images/mockup.jpg'); */
  background-position: 0px 0px;
  opacity: 0.5;
}

.cta-section-image {
  margin-top: 96px;
  border-top-left-radius: 12px;
  border-top-right-radius: 12px;
}

.hero-1-grid {
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  grid-column-gap: 48px;
  grid-row-gap: 48px;
  -ms-grid-columns: 0.75fr 1fr;
  grid-template-columns: 0.75fr 1fr;
  -ms-grid-rows: auto;
  grid-template-rows: auto;
}

.video-wrapper {
  position: relative;
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
  -webkit-justify-content: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
}

.hero-2-grid {
  justify-items: center;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  grid-column-gap: 24px;
  grid-row-gap: 24px;
  -ms-grid-rows: auto;
  grid-template-rows: auto;
}

.hero-2-grid.grid-reversed {
  direction: rtl;
}

.hero-2-grid.reverse {
  direction: rtl;
}

.hero-content {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  max-width: 541px;
  margin-right: auto;
  margin-left: auto;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -webkit-flex-direction: column;
  -ms-flex-direction: column;
  flex-direction: column;
  -webkit-box-align: start;
  -webkit-align-items: flex-start;
  -ms-flex-align: start;
  align-items: flex-start;
  direction: ltr;
}

.footer-2 {
  margin-top: 24px;
}

.medium-heading {
  font-size: 28px;
  line-height: 36px;
  font-weight: 600;
  letter-spacing: -0.01em;
}

.blog-panel-foot {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: justify;
  -webkit-justify-content: space-between;
  -ms-flex-pack: justify;
  justify-content: space-between;
  -webkit-box-align: end;
  -webkit-align-items: flex-end;
  -ms-flex-align: end;
  align-items: flex-end;
}

.panel-image-link {
  position: relative;
  overflow: hidden;
  width: 100%;
  border-top-left-radius: 12px;
  border-top-right-radius: 12px;
}

.panel-image-link.panel-image-link-left {
  border-top-right-radius: 0px;
  border-bottom-left-radius: 12px;
}

.panel-top-image {
  width: 100%;
  border-top-left-radius: 12px;
  border-top-right-radius: 12px;
}

.panel-top-image.panel-image-left {
  border-top-right-radius: 0px;
}

.blog-panel-meta-text {
  margin-right: 12px;
  font-size: 14px;
  line-height: 22px;
  font-weight: 600;
}

.blog-featured-panel-title {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -webkit-flex-direction: column;
  -ms-flex-direction: column;
  flex-direction: column;
  -webkit-box-align: start;
  -webkit-align-items: flex-start;
  -ms-flex-align: start;
  align-items: flex-start;
}

.section-title-with-image {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: justify;
  -webkit-justify-content: space-between;
  -ms-flex-pack: justify;
  justify-content: space-between;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
}

.blog-grid {
  display: -ms-grid;
  display: grid;
  grid-auto-columns: 1fr;
  grid-column-gap: 24px;
  grid-row-gap: 24px;
  -ms-grid-columns: 1fr 1fr 1fr;
  grid-template-columns: 1fr 1fr 1fr;
  -ms-grid-rows: auto;
  grid-template-rows: auto;
}

.empty-state {
  padding: 24px;
  border-radius: 12px;
  background-color: hsla(0, 0%, 100%, 0.1);
  text-align: center;
}

.blog-grid-item {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  min-height: 528px;
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
  -webkit-flex-direction: row;
  -ms-flex-direction: row;
  flex-direction: row;
}

.post-meta-wrapper {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  margin-top: 12px;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
}

.article-container {
  width: 100%;
  max-width: 682px;
  margin-right: auto;
  margin-left: auto;
}

.article h5 {
  margin-top: 48px;
  margin-bottom: 15px;
}

.article img {
  border-radius: 12px;
}

.article figure {
  margin-top: 72px;
  margin-bottom: 72px;
}

.article ul {
  margin-top: 36px;
  margin-bottom: 36px;
}

.article li {
  margin-top: 12px;
  margin-bottom: 12px;
}

.article h4 {
  margin-top: 48px;
  margin-bottom: 15px;
}

.article h2 {
  margin-top: 48px;
  margin-bottom: 15px;
}

.horizontal-rule {
  margin-top: 24px;
  margin-bottom: 24px;
  border-top: 1px solid hsla(0, 0%, 100%, 0.1);
}

.horizontal-rule.blog-post-horizontal-rule {
  margin-top: 96px;
  margin-bottom: 96px;
}

.featured-post-wrapper {
  margin-bottom: 96px;
}

.align-content-center {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
}

.back-to-top-button-wrapper {
  position: fixed;
  right: 24px;
  bottom: 24px;
  z-index: 10;
  width: 48px;
  height: 48px;
  border-style: none;
  border-width: 1px;
  border-color: #12141d;
  border-radius: 50%;
  background-color: #12141d;
}

.wide-grid-vertical {
  display: -ms-grid;
  display: grid;
  grid-auto-columns: 1fr;
  grid-column-gap: 48px;
  grid-row-gap: 48px;
  -ms-grid-columns: 1fr;
  grid-template-columns: 1fr;
  -ms-grid-rows: auto auto;
  grid-template-rows: auto auto;
}

.team-member-heading {
  margin-bottom: 6px;
}

.team-member-footer {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  margin-top: 24px;
  padding-top: 24px;
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
  -webkit-flex-direction: row;
  -ms-flex-direction: row;
  flex-direction: row;
  -webkit-box-pack: justify;
  -webkit-justify-content: space-between;
  -ms-flex-pack: justify;
  justify-content: space-between;
  -webkit-box-align: end;
  -webkit-align-items: flex-end;
  -ms-flex-align: end;
  align-items: flex-end;
  border-top: 1px solid hsla(0, 0%, 100%, 0.1);
}

.team-member-header {
  margin-bottom: 36px;
  padding-bottom: 0px;
}

.section-title-small {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  margin-bottom: 48px;
  -webkit-box-align: start;
  -webkit-align-items: flex-start;
  -ms-flex-align: start;
  align-items: flex-start;
}

.fullscreen-section-content {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  padding-top: 24px;
  padding-bottom: 24px;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-flex: 1;
  -webkit-flex: 1;
  -ms-flex: 1;
  flex: 1;
}

.form-input-link {
  display: block;
  width: 100%;
  margin-top: 6px;
  text-align: right;
}

.form-input-link.text-small {
  margin-top: 8px;
}

.text-center {
  text-align: center;
}

.utility-page-body {
  padding-bottom: 0px;
}

.justify-column-between {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -webkit-flex-direction: column;
  -ms-flex-direction: column;
  flex-direction: column;
  -webkit-box-pack: justify;
  -webkit-justify-content: space-between;
  -ms-flex-pack: justify;
  justify-content: space-between;
}

.field-row {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  margin-bottom: 0px;
  padding-left: 0px;
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
  -webkit-flex-direction: row;
  -ms-flex-direction: row;
  flex-direction: row;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
}

.sign-up-grid-halves {
  width: 100%;
  max-width: 1107px;
  margin-right: auto;
  margin-left: auto;
  grid-column-gap: 24px;
  grid-row-gap: 24px;
  -ms-grid-columns: 1fr 1fr;
  grid-template-columns: 1fr 1fr;
  -ms-grid-rows: auto;
  grid-template-rows: auto;
}

.modal-container {
  position: fixed;
  left: 0px;
  top: 0px;
  z-index: 999;
  display: none;
  width: 100%;
  height: 100vh;
  padding-top: 24px;
  padding-bottom: 24px;
  -webkit-box-pack: center;
  -webkit-justify-content: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
}

.search-form {
  position: relative;
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  margin-bottom: 0px;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
}

.modal-background {
  position: absolute;
  left: 0%;
  top: 0%;
  right: 0%;
  bottom: 0%;
  background-color: rgba(18, 20, 29, 0.9);
}

.modal-panel-bg {
  position: absolute;
  left: 0%;
  top: 0%;
  right: 0%;
  bottom: 0%;
  border-radius: 12px;
  background-color: #12141d;
}

.footer-3-top-row {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  margin-bottom: 96px;
  -webkit-box-pack: justify;
  -webkit-justify-content: space-between;
  -ms-flex-pack: justify;
  justify-content: space-between;
  -webkit-box-align: start;
  -webkit-align-items: flex-start;
  -ms-flex-align: start;
  align-items: flex-start;
}

.footer-3-left-column {
  display: -ms-grid;
  display: grid;
  -webkit-box-align: start;
  -webkit-align-items: start;
  -ms-flex-align: start;
  align-items: start;
  grid-auto-columns: 1fr;
  grid-column-gap: 72px;
  grid-row-gap: 96px;
  -ms-grid-columns: 1fr 1fr 1fr;
  grid-template-columns: 1fr 1fr 1fr;
  -ms-grid-rows: auto;
  grid-template-rows: auto;
}

.footer-contact-methods {
  display: -ms-grid;
  display: grid;
  grid-auto-columns: 1fr;
  grid-column-gap: 24px;
  grid-row-gap: 48px;
  -ms-grid-columns: 1fr;
  grid-template-columns: 1fr;
  -ms-grid-rows: auto auto;
  grid-template-rows: auto auto;
}

.blog-small-heading {
  margin-bottom: 6px;
}

.blog-small-link {
  color: hsla(0, 0%, 100%, 0.5);
  font-weight: 500;
}

.search-result-list {
  display: -ms-grid;
  display: grid;
  grid-auto-columns: 1fr;
  grid-column-gap: 24px;
  grid-row-gap: 24px;
  -ms-grid-columns: 1fr;
  grid-template-columns: 1fr;
  -ms-grid-rows: auto auto;
  grid-template-rows: auto auto;
}

.search-result-paragraph {
  margin-bottom: 0px;
}

.search-page-form {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  width: 100%;
}

.slider {
  height: auto;
  background-color: transparent;
}

.display-none {
  display: none;
}

.slider-navigation-previous {
  left: auto;
  top: auto;
  right: 84px;
  bottom: 24px;
}

.slider-navigation-next {
  left: auto;
  top: auto;
  right: 24px;
  bottom: 24px;
}

.content-on-image-wrapper {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -webkit-flex-direction: column;
  -ms-flex-direction: column;
  flex-direction: column;
  -webkit-box-pack: center;
  -webkit-justify-content: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
}

.content-on-image {
  position: absolute;
}

.content-on-image-image {
  opacity: 0.5;
}

.content-on-image-slider-navigation {
  display: none;
}

.slider-dots {
  bottom: -48px;
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  height: 16px;
  padding-top: 0px;
  -webkit-box-pack: center;
  -webkit-justify-content: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  font-size: 8px;
}

.tabs-horizontal {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  grid-auto-columns: 1fr;
  -ms-grid-columns: 1fr 1fr;
  grid-template-columns: 1fr 1fr;
  -ms-grid-rows: auto auto;
  grid-template-rows: auto auto;
}

.tabs-horizontal-menu {
  display: -ms-grid;
  display: grid;
  width: 100%;
  max-width: 328px;
  margin-right: 12px;
  -webkit-box-align: start;
  -webkit-align-items: start;
  -ms-flex-align: start;
  align-items: start;
  -webkit-align-content: start;
  -ms-flex-line-pack: start;
  align-content: start;
  grid-auto-flow: row;
  grid-auto-columns: 1fr;
  grid-column-gap: 16px;
  grid-row-gap: 12px;
  -ms-grid-columns: 1fr;
  grid-template-columns: 1fr;
  -ms-grid-rows: auto auto;
  grid-template-rows: auto auto;
}

.tab-icon {
  margin-right: 24px;
}

.tabs-content {
  -webkit-box-flex: 1;
  -webkit-flex: 1;
  -ms-flex: 1;
  flex: 1;
}

.tabs-vertical-menu {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
}

.tabs-vertical-tab {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  height: 48px;
  margin-right: 12px;
  margin-bottom: 12px;
  padding-right: 24px;
  padding-left: 24px;
  -webkit-box-pack: center;
  -webkit-justify-content: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-align-self: auto;
  -ms-flex-item-align: auto;
  align-self: auto;
  border-radius: 6px;
  background-color: hsla(0, 0%, 100%, 0.1);
  -webkit-transition: color 200ms ease, background-color 200ms ease;
  transition: color 200ms ease, background-color 200ms ease;
  color: hsla(0, 0%, 100%, 0.5);
}

.tabs-vertical-tab.w--current {
  background-color: hsla(0, 0%, 100%, 0.5);
  color: #fff;
}

.accordion-head {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  padding: 24px 36px;
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
  -webkit-flex-direction: row;
  -ms-flex-direction: row;
  flex-direction: row;
  -webkit-box-pack: justify;
  -webkit-justify-content: space-between;
  -ms-flex-pack: justify;
  justify-content: space-between;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  cursor: pointer;
}

.accordion-head.accordion-head-small {
  padding: 12px 24px;
}

.accordion-body {
  overflow: hidden;
  margin-top: 0px;
  margin-bottom: 36px;
  padding-right: 36px;
  padding-left: 36px;
}

.accordion-body.accordion-body-small {
  margin-top: 12px;
  margin-bottom: 24px;
  padding-right: 24px;
  padding-left: 24px;
}

.accordion-head-text {
  margin-bottom: 0px;
  color: hsla(0, 0%, 100%, 0.5);
}

.accordion-list {
  display: -ms-grid;
  display: grid;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -webkit-flex-direction: column;
  -ms-flex-direction: column;
  flex-direction: column;
  grid-auto-columns: 1fr;
  grid-column-gap: 0px;
  grid-row-gap: 12px;
  -ms-grid-columns: 1fr;
  grid-template-columns: 1fr;
  -ms-grid-rows: auto auto;
  grid-template-rows: auto auto;
}

.pricing-thirds {
  display: -ms-grid;
  display: grid;
  width: 100%;
  justify-items: center;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  grid-auto-columns: 1fr;
  grid-column-gap: 12px;
  grid-row-gap: 12px;
  -ms-grid-columns: 1fr 1fr 1fr;
  grid-template-columns: 1fr 1fr 1fr;
  -ms-grid-rows: auto;
  grid-template-rows: auto;
}

.pricing-thirds.pricing-button-thirds {
  justify-items: stretch;
}

.pricing-row {
  display: -ms-grid;
  display: grid;
  padding: 16px 0px;
  justify-items: start;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  grid-auto-columns: 1fr;
  grid-column-gap: 12px;
  grid-row-gap: 12px;
  -ms-grid-columns: 1.75fr 2.5fr;
  grid-template-columns: 1.75fr 2.5fr;
  -ms-grid-rows: auto;
  grid-template-rows: auto;
  border-top: 1px solid hsla(0, 0%, 100%, 0.1);
  border-radius: 1px;
}

.pricing-row.pricing-header {
  position: -webkit-sticky;
  position: sticky;
  top: 73px;
  z-index: 2;
  padding: 24px 36px;
  border-top-style: none;
  background-color: #12141d;
}

.pricing-row.pricing-title-row {
  border-top-style: none;
}

.wide-case-study-panel-link {
  padding-top: 36px;
  border-top: 1px solid hsla(0, 0%, 100%, 0.1);
  cursor: pointer;
}

.link-block {
  cursor: pointer;
}

.case-study-article-logo {
  margin-bottom: 48px;
}

.case-study-company-details-wrapper {
  margin-top: -72px;
  margin-bottom: 96px;
  border-radius: 12px;
  background-color: #12141d;
}

.case-study-panel-logo {
  margin-bottom: 36px;
  opacity: 0.5;
}

.blog-post-panel-author {
  white-space: nowrap;
}

.account-sign-up-subtext {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
  -webkit-justify-content: center;
  -ms-flex-pack: center;
  justify-content: center;
  font-size: 14px;
}

.rounded-image-small {
  border-radius: 6px;
}

.quote-background-image {
  position: absolute;
  left: 0%;
  top: 0%;
  right: 0%;
  bottom: 0%;
  /* background-image: url('../images/rectangle-5.jpg'); */
  background-position: 50% 50%;
  background-size: cover;
  opacity: 0.15;
}

.faq-grid {
  -ms-grid-columns: 45fr 55fr;
  grid-template-columns: 45fr 55fr;
  -ms-grid-rows: auto;
  grid-template-rows: auto;
}

.job-listing-grid {
  grid-column-gap: 96px;
  grid-row-gap: 24px;
  -ms-grid-columns: 36fr 64fr;
  grid-template-columns: 36fr 64fr;
  -ms-grid-rows: auto;
  grid-template-rows: auto;
}

.job-listing-short-description {
  margin-bottom: 96px;
}

.map {
  height: 528px;
}

.contact-form-grid {
  grid-column-gap: 24px;
  grid-row-gap: 24px;
  -ms-grid-columns: 55fr 45fr;
  grid-template-columns: 55fr 45fr;
  -ms-grid-rows: auto;
  grid-template-rows: auto;
}

.panel-height-large {
  min-height: 528px;
}

.panel-height-medium {
  min-height: 432px;
}

.vertical-metrics-grid {
  display: -ms-grid;
  display: grid;
  grid-auto-columns: 1fr;
  grid-column-gap: 12px;
  grid-row-gap: 12px;
  -ms-grid-columns: 1fr;
  grid-template-columns: 1fr;
  -ms-grid-rows: auto auto;
  grid-template-rows: auto auto;
}

.panel-image-left {
  border-top-left-radius: 12px;
  border-bottom-left-radius: 12px;
}

.list-grid-large {
  display: -ms-grid;
  display: grid;
  grid-auto-columns: 1fr;
  grid-column-gap: 24px;
  grid-row-gap: 24px;
  -ms-grid-columns: 1fr;
  grid-template-columns: 1fr;
  -ms-grid-rows: auto auto;
  grid-template-rows: auto auto;
}

.fixed-notice-container {
  position: fixed;
  left: 24px;
  bottom: 24px;
  z-index: 10;
  max-width: 400px;
  border-radius: 12px;
  background-color: #12141d;
  box-shadow: 0 12px 12px 0 rgba(0, 0, 0, 0.2);
}

.notice-dismiss {
  position: absolute;
  top: 12px;
  right: 12px;
  z-index: 2;
  cursor: pointer;
}

.demo-screenshot {
  margin-bottom: 24px;
  border-radius: 6px;
  opacity: 0.5;
  -webkit-transition: opacity 200ms ease, -webkit-transform 200ms ease;
  transition: opacity 200ms ease, -webkit-transform 200ms ease;
  transition: opacity 200ms ease, transform 200ms ease;
  transition: opacity 200ms ease, transform 200ms ease, -webkit-transform 200ms ease;
}

.demo-screenshot:hover {
  opacity: 1;
  -webkit-transform: scale(1.02);
  -ms-transform: scale(1.02);
  transform: scale(1.02);
}

.demo-link {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -webkit-flex-direction: column;
  -ms-flex-direction: column;
  flex-direction: column;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
}

.demos-grid {
  grid-column-gap: 48px;
  grid-row-gap: 48px;
  -ms-grid-rows: auto;
  grid-template-rows: auto;
}

.grid {
  grid-column-gap: 12px;
  grid-row-gap: 12px;
  -ms-grid-rows: auto;
  grid-template-rows: auto;
}

.dropdown-list-wrapper {
  overflow: hidden;
  padding: 12px;
  border-radius: 12px;
}

.dropdown-grid-halves {
  display: -ms-grid;
  display: grid;
  grid-auto-columns: 1fr;
  grid-column-gap: 0px;
  grid-row-gap: 0px;
  -ms-grid-columns: 1fr 1fr;
  grid-template-columns: 1fr 1fr;
  -ms-grid-rows: auto auto;
  grid-template-rows: auto auto;
}

.style-guide-grid {
  grid-column-gap: 24px;
  grid-row-gap: 24px;
  -ms-grid-columns: 1fr;
  grid-template-columns: 1fr;
}

.div-block {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -webkit-flex-direction: column;
  -ms-flex-direction: column;
  flex-direction: column;
  -webkit-box-pack: start;
  -webkit-justify-content: flex-start;
  -ms-flex-pack: start;
  justify-content: flex-start;
  -webkit-box-align: start;
  -webkit-align-items: flex-start;
  -ms-flex-align: start;
  align-items: flex-start;
  -webkit-align-self: flex-start;
  -ms-flex-item-align: start;
  align-self: flex-start;
}

.grid-2 {
  -ms-grid-columns: 1fr 1fr;
  grid-template-columns: 1fr 1fr;
  -ms-grid-rows: auto;
  grid-template-rows: auto;
}

.grid-3 {
  -ms-grid-rows: auto;
  grid-template-rows: auto;
}

.div-block-2 {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: justify;
  -webkit-justify-content: space-between;
  -ms-flex-pack: justify;
  justify-content: space-between;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-align-self: auto;
  -ms-flex-item-align: auto;
  align-self: auto;
  -webkit-box-flex: 1;
  -webkit-flex: 1;
  -ms-flex: 1;
  flex: 1;
  -o-object-fit: fill;
  object-fit: fill;
}

.text-black {
  color: #000;
}

.grid-4 {
  -ms-grid-columns: 1fr 2fr;
  grid-template-columns: 1fr 2fr;
}

.container-2 {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
  -webkit-flex-direction: row;
  -ms-flex-direction: row;
  flex-direction: row;
  -webkit-box-pack: start;
  -webkit-justify-content: flex-start;
  -ms-flex-pack: start;
  justify-content: flex-start;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-align-self: auto;
  -ms-flex-item-align: auto;
  align-self: auto;
}

.div-block-lr {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  margin-top: 0px;
  margin-bottom: 10px;
  -webkit-box-pack: justify;
  -webkit-justify-content: space-between;
  -ms-flex-pack: justify;
  justify-content: space-between;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
}

.list {
  -webkit-align-self: stretch;
  -ms-flex-item-align: stretch;
  -ms-grid-row-align: stretch;
  align-self: stretch;
  list-style-type: none;
}

.text-lead-line-above {
  margin-top: 20px;
  padding-top: 20px;
  border-top: 1px solid hsla(0, 0%, 100%, 0.5);
  font-family: Inter, sans-serif;
  font-size: 20px;
  line-height: 28px;
  font-weight: 500;
  letter-spacing: 0em;
}

.text-lead-line-above.horizontal-metric-text {
  max-width: 284px;
  margin-left: 24px;
  color: #fff;
}

.text-lead-line-above.blog-post-summary {
  margin-bottom: 96px;
}

.text-lead-line-above.job-listing-short-description {
  margin-bottom: 96px;
}

.text-span {
  color: #fff;
}

.text-span-2 {
  color: #fff;
}

.text-span-3 {
  text-align: left;
}

.space-left-medium {
  margin-left: 12px;
  font-size: 22px;
  line-height: 30px;
}

.circle-medium {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  width: 48px;
  height: 48px;
  margin-bottom: 0px;
  -webkit-box-pack: center;
  -webkit-justify-content: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-flex: 0;
  -webkit-flex: 0 0 auto;
  -ms-flex: 0 0 auto;
  flex: 0 0 auto;
  border-radius: 48px;
  background-color: hsla(0, 0%, 100%, 0.1);
}

.circle-medium.bg-primary-2 {
  background-color: #ff934f;
}

.circle-medium.bg-primary-3 {
  background-color: #3ccf91;
}

.circle-medium.bg-primary-4 {
  background-color: #3c5ccf;
}

.circle-medium.bg-primary-5 {
  background-color: #5132c0;
}

.circle-medium.circle-horizontal {
  margin-right: 24px;
  margin-bottom: 0px;
}

.circle-medium.bg-gray-3 {
  background-color: hsla(0, 0%, 100%, 0.1);
}

.circle-medium.circle-small {
  width: 36px;
  height: 36px;
}

.circle-medium.circle-small.bg-gray-3 {
  margin-bottom: 0px;
}

.circle-medium.bg-primary-1 {
  background-color: #ff715b;
}

.circle-medium.pricing-panel-circle {
  margin-bottom: 0px;
  margin-left: 24px;
}

.circle-medium.video-circle-large {
  position: absolute;
  margin-bottom: 0px;
  background-color: #fff;
  -webkit-transition: -webkit-transform 200ms ease;
  transition: -webkit-transform 200ms ease;
  transition: transform 200ms ease;
  transition: transform 200ms ease, -webkit-transform 200ms ease;
}

.circle-medium.video-circle-large:hover {
  -webkit-transform: scale(0.9);
  -ms-transform: scale(0.9);
  transform: scale(0.9);
}

.panel-body-copy {
  position: relative;
  z-index: 1;
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  width: 100%;
  margin-right: auto;
  margin-left: auto;
  padding: 36px;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -webkit-flex-direction: column;
  -ms-flex-direction: column;
  flex-direction: column;
  -webkit-box-pack: center;
  -webkit-justify-content: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: stretch;
  -webkit-align-items: stretch;
  -ms-flex-align: stretch;
  align-items: stretch;
  -webkit-box-flex: 1;
  -webkit-flex: 1 0 auto;
  -ms-flex: 1 0 auto;
  flex: 1 0 auto;
  border-radius: 0px;
}

.panel-body-copy.justify-content-center {
  -webkit-box-pack: center;
  -webkit-justify-content: center;
  -ms-flex-pack: center;
  justify-content: center;
}

.panel-body-copy.panel-body-small {
  padding: 24px;
}

.panel-body-copy.panel-body-large {
  padding: 48px;
}

.panel-body-copy.panel-body-large.align-content-center {
  padding-top: 48px;
  padding-bottom: 48px;
}

.panel-body-copy.align-content-center {
  -webkit-box-pack: center;
  -webkit-justify-content: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
}

.panel-body-copy.panel-body-extra-small {
  padding: 12px 24px;
}

.panel-body-copy.footer-1-panel-body {
  padding: 72px 72px 48px;
}

.panel-body-copy.footer-2-panel-body {
  display: -ms-grid;
  display: grid;
  padding: 48px;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  grid-auto-columns: 1fr;
  grid-column-gap: 24px;
  grid-row-gap: 24px;
  -ms-grid-columns: 1fr 1fr 1fr;
  grid-template-columns: 1fr 1fr 1fr;
  -ms-grid-rows: auto;
  grid-template-rows: auto;
}

.panel-body-copy.blog-small-panel-body {
  -webkit-box-pack: start;
  -webkit-justify-content: flex-start;
  -ms-flex-pack: start;
  justify-content: flex-start;
  -webkit-box-align: start;
  -webkit-align-items: flex-start;
  -ms-flex-align: start;
  align-items: flex-start;
}

.panel-body-copy.grid-halves {
  display: -ms-grid;
  display: grid;
  grid-auto-columns: 1fr;
  grid-column-gap: 36px;
  grid-row-gap: 36px;
  -ms-grid-columns: 1fr 1fr;
  grid-template-columns: 1fr 1fr;
  -ms-grid-rows: auto;
  grid-template-rows: auto;
}

.panel-body-copy.modal-panel-body {
  border-radius: 12px;
  background-color: hsla(0, 0%, 100%, 0.05);
}

.panel-body-copy.accordion {
  padding: 0px;
}

.panel-body-copy.wide-case-study-panel-body {
  max-width: 35%;
  -webkit-box-flex: 0;
  -webkit-flex: 0 0 auto;
  -ms-flex: 0 0 auto;
  flex: 0 0 auto;
}

.social-list-top {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  margin-right: 0px;
  margin-left: 0px;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  grid-auto-columns: 1fr;
  grid-column-gap: 0px;
  grid-row-gap: 0px;
  -ms-grid-columns: 1fr 1fr;
  grid-template-columns: 1fr 1fr;
  -ms-grid-rows: auto auto;
  grid-template-rows: auto auto;
}

.panel-body-short {
  position: relative;
  z-index: 1;
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  width: 100%;
  margin-right: auto;
  margin-left: auto;
  padding: 10px;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -webkit-flex-direction: column;
  -ms-flex-direction: column;
  flex-direction: column;
  -webkit-box-pack: center;
  -webkit-justify-content: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: stretch;
  -webkit-align-items: stretch;
  -ms-flex-align: stretch;
  align-items: stretch;
  -webkit-box-flex: 1;
  -webkit-flex: 1 0 auto;
  -ms-flex: 1 0 auto;
  flex: 1 0 auto;
  border-radius: 0px;
}

.panel-body-short.justify-content-center {
  -webkit-box-pack: center;
  -webkit-justify-content: center;
  -ms-flex-pack: center;
  justify-content: center;
}

.panel-body-short.panel-body-small {
  padding: 24px;
}

.panel-body-short.panel-body-large {
  padding: 48px;
}

.panel-body-short.panel-body-large.align-content-center {
  padding-top: 48px;
  padding-bottom: 48px;
}

.panel-body-short.align-content-center {
  -webkit-box-pack: center;
  -webkit-justify-content: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
}

.panel-body-short.panel-body-extra-small {
  padding: 12px 24px;
}

.panel-body-short.footer-1-panel-body {
  padding: 72px 72px 48px;
}

.panel-body-short.footer-2-panel-body {
  display: -ms-grid;
  display: grid;
  padding: 48px;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  grid-auto-columns: 1fr;
  grid-column-gap: 24px;
  grid-row-gap: 24px;
  -ms-grid-columns: 1fr 1fr 1fr;
  grid-template-columns: 1fr 1fr 1fr;
  -ms-grid-rows: auto;
  grid-template-rows: auto;
}

.panel-body-short.blog-small-panel-body {
  -webkit-box-pack: start;
  -webkit-justify-content: flex-start;
  -ms-flex-pack: start;
  justify-content: flex-start;
  -webkit-box-align: start;
  -webkit-align-items: flex-start;
  -ms-flex-align: start;
  align-items: flex-start;
}

.panel-body-short.grid-halves {
  display: -ms-grid;
  display: grid;
  grid-auto-columns: 1fr;
  grid-column-gap: 36px;
  grid-row-gap: 36px;
  -ms-grid-columns: 1fr 1fr;
  grid-template-columns: 1fr 1fr;
  -ms-grid-rows: auto;
  grid-template-rows: auto;
}

.panel-body-short.modal-panel-body {
  border-radius: 12px;
  background-color: hsla(0, 0%, 100%, 0.05);
}

.panel-body-short.accordion {
  padding: 0px;
}

.panel-body-short.wide-case-study-panel-body {
  max-width: 35%;
  -webkit-box-flex: 0;
  -webkit-flex: 0 0 auto;
  -ms-flex: 0 0 auto;
  flex: 0 0 auto;
}

.div-block-6 {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: justify;
  -webkit-justify-content: space-between;
  -ms-flex-pack: justify;
  justify-content: space-between;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
}

.text-block {
  font-weight: 500;
}

.text-block-small {
  color: rgba(0, 0, 0, 0.7);
  font-size: 12px;
  line-height: 16px;
  font-weight: 500;
}

.div-block-small {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: justify;
  -webkit-justify-content: space-between;
  -ms-flex-pack: justify;
  justify-content: space-between;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  font-size: 12px;
  line-height: 16px;
}

.button-extra-small {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  height: 36px;
  padding-right: 24px;
  padding-left: 24px;
  -webkit-box-pack: center;
  -webkit-justify-content: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  border-style: solid;
  border-width: 1px;
  border-color: #ff715b;
  border-radius: 6px;
  background-color: #ff715b;
  box-shadow: inset 0 0 20px 20px transparent;
  -webkit-transition: box-shadow 200ms ease;
  transition: box-shadow 200ms ease;
  font-size: 12px;
  font-weight: 500;
}

.button-extra-small:hover {
  box-shadow: inset 0 0 20px 20px rgba(0, 0, 0, 0.1);
}

.app-body {
  background-color: #fff;
  padding-bottom: 0;
}

@media screen and (min-width: 1920px) {
  .rounded-image {
    min-height: auto;
    min-width: 100%;
    -o-object-fit: fill;
    object-fit: fill;
  }
  .content-width-extra-large {
    max-width: none;
  }
  .space-left-medium {
    margin-left: 20px;
    font-size: 22px;
    line-height: 30px;
  }
  .circle-medium {
    width: 48px;
    height: 48px;
    margin-bottom: 0px;
  }
  .image {
    margin-top: -40px;
    margin-bottom: -40px;
    padding-left: 0px;
  }
  .image-2 {
    margin-top: -40px;
    margin-bottom: -40px;
  }
  .div-block-4 {
    margin-top: 20px;
    margin-bottom: 20px;
  }
  .div-block-5 {
    margin-top: 40px;
  }
  .text-block-small {
    font-size: 16px;
    line-height: 20px;
  }
  .button-extra-small {
    font-size: 16px;
    line-height: 20px;
  }
}

@media screen and (max-width: 991px) {
  blockquote {
    margin-top: 36px;
    margin-bottom: 36px;
  }
  .section {
    margin-top: 96px;
    margin-bottom: 96px;
  }
  .section.section-small {
    margin-top: 72px;
    margin-bottom: 72px;
  }
  .rounded-image.section-title-image {
    margin-top: 48px;
    margin-left: 0px;
  }
  .panel.section {
    padding-top: 60px;
    padding-bottom: 60px;
  }
  .panel.section.hero-panel {
    min-height: 50vh;
  }
  .panel.panel-wide {
    max-width: 612px;
    margin-right: auto;
    margin-left: auto;
    -ms-grid-columns: 1fr;
    grid-template-columns: 1fr;
  }
  .panel.wide-case-study-panel {
    max-width: 612px;
    margin-right: auto;
    margin-left: auto;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -webkit-flex-direction: column;
    -ms-flex-direction: column;
    flex-direction: column;
  }
  .style-guide-container {
    grid-row-gap: 48px;
    -ms-grid-columns: 1fr;
    grid-template-columns: 1fr;
  }
  .style-guide-content-item {
    margin-right: 12px;
    margin-bottom: 12px;
  }
  .display-heading-1 {
    font-size: 88px;
    line-height: 88px;
  }
  .display-heading-2 {
    font-size: 72px;
    line-height: 72px;
  }
  .display-heading-3 {
    font-size: 54px;
    line-height: 54px;
  }
  .display-heading-4.space-bottom-large {
    margin-bottom: 36px;
  }
  .text-lead.blog-post-summary {
    margin-bottom: 72px;
  }
  .text-lead.job-listing-short-description {
    margin-bottom: 48px;
  }
  .row.content-width-extra-large.large-text-row {
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -webkit-flex-direction: column;
    -ms-flex-direction: column;
    flex-direction: column;
  }
  .button-large-outline {
    text-align: center;
  }
  .panel-body.footer-1-panel-body {
    padding-top: 48px;
    padding-right: 48px;
    padding-left: 48px;
  }
  .panel-body.footer-2-panel-body {
    padding: 36px;
  }
  .panel-body.wide-case-study-panel-body {
    max-width: none;
  }
  .grid-halves {
    max-width: 824px;
    margin-right: auto;
    margin-left: auto;
    -ms-grid-columns: 1fr;
    grid-template-columns: 1fr;
  }
  .grid-thirds {
    max-width: 824px;
    min-height: auto;
    margin-right: auto;
    margin-left: auto;
    -ms-grid-columns: 1fr;
    grid-template-columns: 1fr;
  }
  .grid-thirds.space-bottom {
    -ms-grid-columns: 1fr;
    grid-template-columns: 1fr;
  }
  .content-width-medium.article-title-wrapper {
    margin-bottom: 72px;
  }
  .grid-two-thirds {
    max-width: 824px;
    min-height: 0px;
    margin-right: auto;
    margin-left: auto;
    -ms-grid-columns: 1fr;
    grid-template-columns: 1fr;
  }
  .panel-wide {
    -ms-grid-columns: 1fr;
    grid-template-columns: 1fr;
  }
  .brand-row.space-bottom-large {
    margin-bottom: 24px;
  }
  .nav-link {
    padding-top: 6px;
    padding-bottom: 6px;
  }
  .nav-link.row {
    -webkit-box-align: center;
    -webkit-align-items: center;
    -ms-flex-align: center;
    align-items: center;
  }
  .navbar {
    padding-top: 12px;
    padding-bottom: 12px;
  }
  .navbar-controls {
    -webkit-box-align: center;
    -webkit-align-items: center;
    -ms-flex-align: center;
    align-items: center;
  }
  .dropdown-list.w--open {
    position: relative;
  }
  .nav-menu {
    padding: 12px 4px;
    border-bottom-left-radius: 12px;
    border-bottom-right-radius: 12px;
    background-color: white;
  }
  .menu-button {
    margin-left: 12px;
    padding: 12px 0px 12px 12px;
  }
  .menu-button.w--open {
    background-color: transparent;
  }
  .footer-1-top-row {
    margin-bottom: 72px;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -webkit-flex-direction: column;
    -ms-flex-direction: column;
    flex-direction: column;
  }
  .footer-menu-grids {
    margin-top: 48px;
  }
  .form-thirds {
    -ms-grid-columns: 1fr;
    grid-template-columns: 1fr;
  }
  .cta-row-1 {
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -webkit-flex-direction: column;
    -ms-flex-direction: column;
    flex-direction: column;
    justify-items: center;
    -ms-grid-columns: 1fr;
    grid-template-columns: 1fr;
    text-align: center;
  }
  .cta-row-2 {
    grid-column-gap: 36px;
    grid-row-gap: 36px;
    -ms-grid-columns: 2.5fr;
    grid-template-columns: 2.5fr;
  }
  .pricing-panel-2-top-row {
    margin-bottom: 24px;
  }
  .cta-section-image {
    margin-top: 60px;
  }
  .cta-section-image.content-width-large {
    max-width: 612px;
  }
  .hero-1-grid {
    justify-items: center;
    -ms-grid-columns: 1fr;
    grid-template-columns: 1fr;
    text-align: center;
  }
  .hero-2-grid {
    grid-column-gap: 48px;
    grid-row-gap: 48px;
    -ms-grid-columns: 1fr;
    grid-template-columns: 1fr;
    text-align: center;
  }
  .hero-content {
    -webkit-box-align: center;
    -webkit-align-items: center;
    -ms-flex-align: center;
    align-items: center;
  }
  .blog-panel-foot {
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -webkit-flex-direction: column;
    -ms-flex-direction: column;
    flex-direction: column;
    -webkit-box-align: start;
    -webkit-align-items: flex-start;
    -ms-flex-align: start;
    align-items: flex-start;
  }
  .panel-image-link.panel-image-link-left {
    border-top-right-radius: 12px;
    border-bottom-left-radius: 0px;
  }
  .panel-top-image.panel-image-left {
    border-top-right-radius: 12px;
  }
  .blog-panel-meta-text {
    margin-right: 0px;
    margin-bottom: 12px;
  }
  .section-title-with-image {
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -webkit-flex-direction: column;
    -ms-flex-direction: column;
    flex-direction: column;
    text-align: center;
  }
  .blog-grid {
    -ms-grid-columns: 1fr 1fr;
    grid-template-columns: 1fr 1fr;
  }
  .blog-grid-item {
    min-height: auto;
  }
  .article h5 {
    margin-top: 48px;
    margin-bottom: 15px;
  }
  .article figure {
    margin-top: 48px;
    margin-bottom: 48px;
  }
  .article ul {
    margin-top: 36px;
    margin-bottom: 36px;
  }
  .horizontal-rule.blog-post-horizontal-rule {
    margin-top: 72px;
    margin-bottom: 72px;
  }
  .featured-post-wrapper {
    margin-bottom: 72px;
  }
  .sign-up-grid-halves {
    max-width: 612px;
    -ms-grid-columns: 1fr;
    grid-template-columns: 1fr;
  }
  .footer-3-top-row {
    margin-bottom: 72px;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -webkit-flex-direction: column;
    -ms-flex-direction: column;
    flex-direction: column;
    -webkit-box-align: stretch;
    -webkit-align-items: stretch;
    -ms-flex-align: stretch;
    align-items: stretch;
  }
  .footer-3-left-column {
    margin-bottom: 48px;
  }
  .content-on-image {
    padding-right: 24px;
    padding-left: 24px;
  }
  .slider-dots.panel-slider-dots {
    position: relative;
    bottom: 0px;
    margin-top: 24px;
  }
  .tabs-horizontal {
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -webkit-flex-direction: column;
    -ms-flex-direction: column;
    flex-direction: column;
  }
  .tabs-horizontal-menu {
    max-width: none;
    margin-right: 0px;
    margin-bottom: 12px;
    grid-column-gap: 12px;
    -ms-grid-columns: 1fr 1fr;
    grid-template-columns: 1fr 1fr;
  }
  .case-study-company-details-wrapper {
    margin-bottom: 72px;
  }
  .account-sign-up-subtext {
    display: none;
  }
  .faq-grid {
    grid-row-gap: 48px;
    -ms-grid-columns: 1fr;
    grid-template-columns: 1fr;
  }
  .job-listing-grid {
    grid-row-gap: 48px;
    -ms-grid-columns: 1fr;
    grid-template-columns: 1fr;
  }
  .job-listing-short-description {
    margin-bottom: 48px;
  }
  .contact-form-grid {
    -ms-grid-columns: 55fr;
    grid-template-columns: 55fr;
  }
  .grid-container {
    max-width: 612px;
  }
  .panel-image-left {
    border-top-right-radius: 12px;
    border-bottom-left-radius: 0px;
  }
  .dropdown-list-wrapper {
    position: relative;
  }
  .text-lead-line-above.blog-post-summary {
    margin-bottom: 72px;
  }
  .text-lead-line-above.job-listing-short-description {
    margin-bottom: 48px;
  }
  .panel-body-copy.footer-1-panel-body {
    padding-top: 48px;
    padding-right: 48px;
    padding-left: 48px;
  }
  .panel-body-copy.footer-2-panel-body {
    padding: 36px;
  }
  .panel-body-copy.wide-case-study-panel-body {
    max-width: none;
  }
  .panel-body-short.footer-1-panel-body {
    padding-top: 48px;
    padding-right: 48px;
    padding-left: 48px;
  }
  .panel-body-short.footer-2-panel-body {
    padding: 36px;
  }
  .panel-body-short.wide-case-study-panel-body {
    max-width: none;
  }
}

@media screen and (max-width: 767px) {
  body {
    padding-bottom: 24px;
  }
  h1 {
    font-size: 32px;
    line-height: 40px;
  }
  h2 {
    font-size: 28px;
    line-height: 36px;
  }
  h4 {
    font-size: 20px;
    line-height: 28px;
  }
  blockquote {
    margin-top: 24px;
    margin-bottom: 24px;
    padding: 24px;
  }
  .section {
    margin-top: 72px;
    margin-bottom: 72px;
  }
  .section.section-small {
    margin-top: 48px;
    margin-bottom: 48px;
  }
  .rounded-image {
    border-radius: 6px;
  }
  .panel.section {
    padding-top: 48px;
    padding-bottom: 48px;
  }
  .panel.section.hero-panel {
    min-height: auto;
  }
  .panel.bg-white.panel-on-image {
    position: relative;
    right: 0px;
    bottom: 0px;
    max-width: 100%;
  }
  .panel.panel-wide {
    max-width: 400px;
  }
  .panel.tab-panel {
    -webkit-box-pack: center;
    -webkit-justify-content: center;
    -ms-flex-pack: center;
    justify-content: center;
  }
  .panel.wide-case-study-panel {
    max-width: 400px;
  }
  .style-guide-container {
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -webkit-flex-direction: column;
    -ms-flex-direction: column;
    flex-direction: column;
  }
  .display-heading-1 {
    font-size: 54px;
    line-height: 54px;
  }
  .display-heading-2 {
    font-size: 54px;
    line-height: 54px;
  }
  .display-heading-3 {
    font-size: 54px;
    line-height: 54px;
  }
  .display-heading-4 {
    font-size: 30px;
    line-height: 44px;
  }
  .text-lead.blog-post-summary {
    margin-bottom: 48px;
  }
  .text-lead.job-listing-short-description {
    margin-bottom: 24px;
  }
  .row.content-width-extra-large.large-text-row {
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -webkit-flex-direction: column;
    -ms-flex-direction: column;
    flex-direction: column;
    -webkit-align-content: flex-start;
    -ms-flex-line-pack: start;
    align-content: flex-start;
  }
  .text-small.footer-2-subtext {
    text-align: center;
  }
  .button-large {
    height: 48px;
  }
  .button-large.bg-primary-3.horizontal-form-button {
    margin-left: 0px;
  }
  .button-large.bg-primary-5.horizontal-form-button {
    margin-left: 0px;
  }
  .form-input.form-input-large {
    height: 48px;
    padding-left: 20px;
  }
  .panel-body.panel-body-large {
    padding: 36px;
  }
  .panel-body.footer-1-panel-body {
    padding-right: 36px;
    padding-bottom: 24px;
    padding-left: 36px;
  }
  .panel-body.footer-2-panel-body {
    justify-items: center;
    -ms-grid-columns: 1fr;
    grid-template-columns: 1fr;
  }
  .panel-body.grid-halves {
    -ms-grid-columns: 1fr;
    grid-template-columns: 1fr;
  }
  .grid-halves.space-bottom {
    -ms-grid-columns: 1fr;
    grid-template-columns: 1fr;
  }
  .grid-thirds.space-bottom {
    -ms-grid-columns: 1fr;
    grid-template-columns: 1fr;
  }
  .content-width-medium.article-title-wrapper {
    margin-bottom: 48px;
  }
  .large-heading {
    font-size: 32px;
    line-height: 40px;
  }
  .section-title {
    margin-bottom: 48px;
  }
  .quote-on-image {
    display: -ms-grid;
    display: grid;
    width: 100%;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -webkit-flex-direction: column;
    -ms-flex-direction: column;
    flex-direction: column;
    -webkit-box-align: stretch;
    -webkit-align-items: stretch;
    -ms-flex-align: stretch;
    align-items: stretch;
    grid-auto-columns: 1fr;
    grid-column-gap: 24px;
    grid-row-gap: 24px;
    -ms-grid-columns: 1fr;
    grid-template-columns: 1fr;
    -ms-grid-rows: auto auto;
    grid-template-rows: auto auto;
  }
  .rounded {
    border-radius: 6px;
  }
  .avatar.avatar-large {
    width: 72px;
    height: 72px;
  }
  .brand-row.align-content-left {
    margin-left: 0px;
  }
  .brand-image {
    margin-right: 12px;
    margin-left: 12px;
  }
  .footer-1-top-row {
    margin-bottom: 48px;
  }
  .footer-1-bottom-row {
    display: -ms-grid;
    display: grid;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -webkit-flex-direction: column;
    -ms-flex-direction: column;
    flex-direction: column;
    justify-items: center;
    grid-auto-columns: 1fr;
    grid-column-gap: 24px;
    grid-row-gap: 24px;
    -ms-grid-columns: 1fr;
    grid-template-columns: 1fr;
    -ms-grid-rows: auto auto;
    grid-template-rows: auto auto;
  }
  .footer-menu-grids {
    margin-top: 36px;
  }
  .navbar-buttons {
    display: none;
  }
  .horizontal-form {
    display: -ms-grid;
    display: grid;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -webkit-flex-direction: column;
    -ms-flex-direction: column;
    flex-direction: column;
    -webkit-box-align: stretch;
    -webkit-align-items: stretch;
    -ms-flex-align: stretch;
    align-items: stretch;
    grid-auto-columns: 1fr;
    grid-column-gap: 12px;
    grid-row-gap: 12px;
    -ms-grid-columns: 1fr;
    grid-template-columns: 1fr;
    -ms-grid-rows: auto auto;
    grid-template-rows: auto auto;
  }
  .form-thirds {
    -ms-grid-columns: 1fr;
    grid-template-columns: 1fr;
  }
  .cta-section-image {
    margin-top: 24px;
  }
  .cta-section-image.content-width-large {
    max-width: 80%;
  }
  .blog-grid {
    max-width: 400px;
    margin-right: auto;
    margin-left: auto;
    -ms-grid-columns: 1fr;
    grid-template-columns: 1fr;
  }
  .post-meta-wrapper {
    margin-top: 12px;
  }
  .article h5 {
    margin-top: 24px;
  }
  .article img {
    border-radius: 6px;
  }
  .article figure {
    margin-top: 24px;
    margin-bottom: 24px;
  }
  .article ul {
    margin-top: 24px;
    margin-bottom: 24px;
  }
  .horizontal-rule.blog-post-horizontal-rule {
    margin-top: 24px;
    margin-bottom: 24px;
  }
  .featured-post-wrapper {
    margin-bottom: 48px;
  }
  .back-to-top-button-wrapper {
    display: none;
  }
  .wide-grid-vertical {
    grid-column-gap: 24px;
    grid-row-gap: 24px;
  }
  .team-member-header {
    margin-bottom: 24px;
  }
  .section-title-small {
    margin-bottom: 24px;
    -webkit-box-pack: center;
    -webkit-justify-content: center;
    -ms-flex-pack: center;
    justify-content: center;
  }
  .sign-up-grid-halves {
    max-width: 400px;
  }
  .footer-3-top-row {
    margin-bottom: 48px;
  }
  .slider-dots.panel-slider-dots {
    position: relative;
    bottom: 0px;
    margin-top: 24px;
  }
  .tabs-horizontal-menu {
    justify-items: stretch;
    -webkit-box-align: stretch;
    -webkit-align-items: stretch;
    -ms-flex-align: stretch;
    align-items: stretch;
  }
  .tab-icon {
    max-width: 36px;
  }
  .tabs-vertical-menu {
    -webkit-flex-wrap: wrap;
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;
  }
  .pricing-row {
    justify-items: center;
    -ms-grid-columns: 1.75fr;
    grid-template-columns: 1.75fr;
    -ms-grid-rows: auto auto;
    grid-template-rows: auto auto;
    text-align: center;
  }
  .pricing-row.pricing-header {
    padding-top: 12px;
    padding-bottom: 12px;
    -ms-grid-rows: auto;
    grid-template-rows: auto;
    background-color: #12141d;
  }
  .pricing-row.pricing-title-row {
    padding-top: 0px;
    padding-bottom: 0px;
  }
  .pricing-line-title {
    margin-bottom: 12px;
    color: #fff;
  }
  .case-study-company-details-wrapper {
    margin-top: 24px;
    margin-bottom: 48px;
  }
  .pricing-header-row-spacer {
    display: none;
  }
  .rounded-image-small {
    border-radius: 6px;
  }
  .faq-grid {
    grid-row-gap: 24px;
  }
  .job-listing-short-description {
    margin-bottom: 24px;
  }
  .contact-form-grid {
    grid-row-gap: 12px;
  }
  .demo-screenshot {
    opacity: 1;
  }
  .text-lead-line-above.blog-post-summary {
    margin-bottom: 48px;
  }
  .text-lead-line-above.job-listing-short-description {
    margin-bottom: 24px;
  }
  .panel-body-copy.panel-body-large {
    padding: 36px;
  }
  .panel-body-copy.footer-1-panel-body {
    padding-right: 36px;
    padding-bottom: 24px;
    padding-left: 36px;
  }
  .panel-body-copy.footer-2-panel-body {
    justify-items: center;
    -ms-grid-columns: 1fr;
    grid-template-columns: 1fr;
  }
  .panel-body-copy.grid-halves {
    -ms-grid-columns: 1fr;
    grid-template-columns: 1fr;
  }
  .panel-body-short.panel-body-large {
    padding: 36px;
  }
  .panel-body-short.footer-1-panel-body {
    padding-right: 36px;
    padding-bottom: 24px;
    padding-left: 36px;
  }
  .panel-body-short.footer-2-panel-body {
    justify-items: center;
    -ms-grid-columns: 1fr;
    grid-template-columns: 1fr;
  }
  .panel-body-short.grid-halves {
    -ms-grid-columns: 1fr;
    grid-template-columns: 1fr;
  }
}

@media screen and (max-width: 479px) {
  body {
    padding-bottom: 12px;
  }
  h1 {
    font-size: 32px;
    line-height: 40px;
  }
  h2 {
    font-size: 28px;
    line-height: 36px;
  }
  h3 {
    font-size: 24px;
    line-height: 30px;
  }
  blockquote {
    padding: 24px;
    border-radius: 6px;
    font-size: 20px;
    line-height: 30px;
  }
  .container {
    padding-right: 12px;
    padding-left: 12px;
  }
  .section {
    margin-top: 20px;
    margin-bottom: 20px;
  }
  .section.no-bottom-space {
    margin-top: 20px;
    margin-bottom: 20px;
  }
  .section.section-small {
    margin-top: 24px;
    margin-bottom: 24px;
  }
  .rounded-image {
    border-radius: 12px;
  }
  .rounded-image.section-title-image {
    margin-top: 24px;
  }
  .panel {
    border-radius: 6px;
  }
  .panel.section {
    margin-top: 12px;
    margin-bottom: 12px;
  }
  .card-heading {
    font-size: 28px;
    line-height: 32px;
  }
  .display-heading-1 {
    margin-bottom: 24px;
    font-size: 40px;
    line-height: 44px;
  }
  .display-heading-2 {
    font-size: 40px;
    line-height: 44px;
  }
  .display-heading-3 {
    font-size: 40px;
    line-height: 44px;
  }
  .display-heading-4.space-bottom-large {
    margin-bottom: 24px;
  }
  .display-heading-4.space-bottom {
    margin-bottom: 24px;
  }
  .text-lead {
    font-size: 16px;
    line-height: 24px;
  }
  .text-lead.blog-post-summary {
    margin-bottom: 24px;
  }
  .text-small.form-subtext {
    margin-top: 12px;
  }
  .button-large.bg-primary-3.horizontal-form-button {
    margin-left: 0px;
  }
  .button-large.bg-primary-5.horizontal-form-button {
    margin-left: 0px;
  }
  .form-input.form-input-large.search-form-input {
    margin-right: 0px;
    margin-bottom: 12px;
  }
  .badge {
    height: 24px;
    min-width: 24px;
    font-size: 14px;
  }
  .panel-body {
    padding: 24px;
  }
  .panel-body.panel-body-large {
    padding: 24px;
  }
  .grid-halves {
    grid-row-gap: 12px;
    -ms-grid-columns: 1fr;
    grid-template-columns: 1fr;
  }
  .grid-thirds {
    grid-row-gap: 12px;
    -ms-grid-columns: 1fr;
    grid-template-columns: 1fr;
  }
  .content-width-medium.article-title-wrapper {
    margin-bottom: 24px;
  }
  .grid-two-thirds {
    grid-column-gap: 12px;
    grid-row-gap: 12px;
  }
  .section-title {
    margin-bottom: 24px;
  }
  .quote-on-image {
    grid-column-gap: 12px;
    grid-row-gap: 12px;
  }
  .avatar.blog-panel-avatar {
    display: none;
  }
  .dropdown-item {
    min-width: auto;
  }
  .footer-1-top-row {
    margin-right: auto;
    margin-left: auto;
    text-align: center;
  }
  .vertical-menu-grid {
    justify-items: center;
  }
  .footer-1-bottom-row {
    text-align: center;
  }
  .footer-menu-grids {
    -ms-grid-columns: 1fr;
    grid-template-columns: 1fr;
  }
  .social-list.team-member-social-list {
    margin-top: 12px;
  }
  .form-grid-halves {
    -ms-grid-columns: 1fr;
    grid-template-columns: 1fr;
  }
  .horizontal-form {
    display: -ms-grid;
    display: grid;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -webkit-flex-direction: column;
    -ms-flex-direction: column;
    flex-direction: column;
    -webkit-box-align: stretch;
    -webkit-align-items: stretch;
    -ms-flex-align: stretch;
    align-items: stretch;
    grid-auto-columns: 1fr;
  }
  .vertical-text-grid.content-width-extra-small {
    grid-column-gap: 24px;
    grid-row-gap: 24px;
  }
  .cta-row-1 {
    padding-right: 0px;
    padding-left: 0px;
    grid-column-gap: 36px;
    grid-row-gap: 36px;
  }
  .cta-row-2 {
    grid-column-gap: 0px;
    grid-row-gap: 24px;
  }
  .pricing-panel-top-row {
    margin-bottom: 12px;
  }
  .hero-bg-1 {
    border-radius: 6px;
  }
  .cta-section-image.content-width-large {
    max-width: 100%;
    border-radius: 0px 0px 12px 12px;
  }
  .medium-heading {
    font-size: 24px;
    line-height: 30px;
  }
  .panel-image-link {
    border-top-left-radius: 6px;
    border-top-right-radius: 6px;
  }
  .panel-image-link.panel-image-link-left {
    border-top-right-radius: 6px;
  }
  .panel-top-image {
    width: 100%;
    border-top-left-radius: 6px;
    border-top-right-radius: 6px;
  }
  .blog-grid {
    grid-column-gap: 12px;
    grid-row-gap: 12px;
  }
  .empty-state {
    padding: 12px;
    border-radius: 6px;
  }
  .post-meta-wrapper {
    margin-top: 0px;
  }
  .featured-post-wrapper {
    margin-bottom: 24px;
  }
  .wide-grid-vertical {
    grid-column-gap: 12px;
    grid-row-gap: 12px;
  }
  .team-member-footer {
    margin-top: 0px;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -webkit-flex-direction: column;
    -ms-flex-direction: column;
    flex-direction: column;
    -webkit-box-align: center;
    -webkit-align-items: center;
    -ms-flex-align: center;
    align-items: center;
    text-align: center;
  }
  .team-member-header {
    text-align: center;
  }
  .section-title-small {
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -webkit-flex-direction: column;
    -ms-flex-direction: column;
    flex-direction: column;
    -webkit-box-align: center;
    -webkit-align-items: center;
    -ms-flex-align: center;
    align-items: center;
  }
  .footer-3-left-column {
    justify-items: center;
    grid-row-gap: 24px;
    -ms-grid-columns: 1fr;
    grid-template-columns: 1fr;
    text-align: center;
  }
  .search-result-list {
    grid-row-gap: 12px;
  }
  .search-page-form {
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -webkit-flex-direction: column;
    -ms-flex-direction: column;
    flex-direction: column;
  }
  .slider-navigation-previous {
    display: none;
  }
  .slider-navigation-next {
    display: none;
  }
  .content-on-image {
    position: relative;
    margin-top: 24px;
    padding-right: 0px;
    padding-left: 0px;
  }
  .content-on-image-image {
    opacity: 1;
  }
  .content-on-image-slider-navigation {
    position: relative;
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    height: 12px;
    margin-top: 24px;
    margin-bottom: 24px;
    padding-top: 0px;
    font-size: 10px;
  }
  .tabs-horizontal-menu {
    grid-auto-flow: row;
    -ms-grid-columns: 1fr;
    grid-template-columns: 1fr;
  }
  .tabs-vertical-tab:hover {
    background-color: hsla(0, 0%, 100%, 0.5);
    color: #fff;
  }
  .pricing-thirds.pricing-button-thirds {
    display: -ms-grid;
    display: grid;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -webkit-flex-direction: column;
    -ms-flex-direction: column;
    flex-direction: column;
    grid-auto-flow: row;
    grid-auto-columns: 1fr;
    grid-column-gap: 6px;
    grid-row-gap: 6px;
    -ms-grid-columns: 1fr;
    grid-template-columns: 1fr;
    -ms-grid-rows: auto;
    grid-template-rows: auto;
  }
  .align-right {
    text-align: right;
  }
  .grid-image {
    border-radius: 6px;
  }
  .panel-image-left {
    border-top-left-radius: 6px;
    border-top-right-radius: 6px;
  }
  .list-grid-large {
    grid-column-gap: 12px;
    grid-row-gap: 12px;
  }
  .fixed-notice-container {
    left: 12px;
    bottom: 12px;
    max-width: 298px;
    border-radius: 6px;
  }
  .dropdown-grid-halves {
    -ms-grid-columns: 1fr;
    grid-template-columns: 1fr;
  }
  .grid-2 {
    -webkit-align-self: auto;
    -ms-flex-item-align: auto;
    -ms-grid-row-align: auto;
    align-self: auto;
    -webkit-box-flex: 0;
    -webkit-flex: 0 0 auto;
    -ms-flex: 0 0 auto;
    flex: 0 0 auto;
  }
  .grid-3 {
    -ms-grid-columns: 1fr;
    grid-template-columns: 1fr;
  }
  .section-2 {
    margin-top: 20px;
    margin-bottom: 20px;
  }
  .section-3 {
    margin-top: 20px;
    margin-bottom: 20px;
  }
  .text-lead-line-above {
    font-size: 16px;
    line-height: 24px;
  }
  .text-lead-line-above.blog-post-summary {
    margin-bottom: 24px;
  }
  .div-block-3 {
    text-align: center;
  }
  .panel-body-copy {
    padding: 24px;
  }
  .panel-body-copy.panel-body-large {
    padding: 24px;
  }
  .image-2 {
    margin-top: -20px;
    margin-bottom: -20px;
  }
  .div-block-4 {
    text-align: center;
  }
  .social-list-top.team-member-social-list {
    margin-top: 12px;
  }
  .panel-body-short {
    padding: 10px;
  }
  .panel-body-short.panel-body-large {
    padding: 24px;
  }
  .text-block-small {
    font-size: 14px;
    line-height: 18px;
  }
  .button-extra-small {
    font-size: 14px;
    line-height: 18px;
  }
}

#w-node-ec9110036bd5-998539b8 {
  -ms-grid-column-align: end;
  justify-self: end;
}

#w-node-ae092c2fe6c3-c1853a3e {
  -ms-grid-column-align: end;
  justify-self: end;
}

/* @font-face {
  font-family: 'Inter';
  src: url('../fonts/Inter-Bold.woff') format('woff'), url('../fonts/Inter-Bold.ttf') format('truetype'), url('../fonts/Inter-Bold.otf') format('opentype');
  font-weight: 700;
  font-style: normal;
}
@font-face {
  font-family: 'Inter';
  src: url('../fonts/Inter-Medium.woff') format('woff'), url('../fonts/Inter-Medium.ttf') format('truetype'), url('../fonts/Inter-Medium.otf') format('opentype');
  font-weight: 500;
  font-style: normal;
}
@font-face {
  font-family: 'Inter';
  src: url('../fonts/Inter-SemiBold.woff') format('woff'), url('../fonts/Inter-SemiBold.ttf') format('truetype'), url('../fonts/Inter-SemiBold.otf') format('opentype');
  font-weight: 600;
  font-style: normal;
} */
